import React from 'react'
import forgetPassword from './../../../assets/ForogtPassword.png'

const ForgetPassword = () => {
    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-sm flex justify-center items-end z-50">
            <div
                // ref={signInRef}
                className="bg-white rounded-3xl shadow-lg max-w-6xl h-auto overflow-y-auto relative p-20 mb-8 w-[60%]"
            >
                <div className="space-y-4">
                    <div className="flex items-center justify-center">
                        <img src={forgetPassword} alt="Account Success" className="size-32" />
                    </div>
                    <h1 className='font-bold text-lg md:text-xl lg:text-2xl xl:text-3xl 2xl:text-4xl text-center'>Forgot Your Password?</h1>
                    <div className="w-[80%] mx-auto text-[#0F0F0F80] text-sm md:text-base lg:text-lg text-center">
                        Do Not worry! We will help you in logging in back to your MedicaBuyer account safely! Enter Your Email address and proceed further!
                    </div>
                    <div className='py-2 ' >
                        <div className='text-[#848484]'>Email ID</div>
                        <input
                            type="email"
                            placeholder="Enter Email ID"
                            className={`text-[#848484]/50 bg-[#F3F4F4] rounded-2xl p-4 w-full outline-none 
                                `}
                        // ${errors.email ? 'border border-red-500' : ''}
                        />
                        {/* {errors.email && <p className="text-red-500 text-xs">{errors.email}</p>} */}
                    </div>
                    <div className=" text-sm lg:text-lg flex justify-center">
                        <button
                            type="button"
                            className="rounded-full bg-[#1A444C] text-white w-full font-semibold p-2 lg:p-4"
                        // onClick={onClose}
                        >
                            Send Email
                        </button>
                    </div>
                    <div className='text-center text-black/50'>
                        Didn’t receive? <span className='font-semibold text-[#1A444C]'>Resend</span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ForgetPassword
