import React from 'react';
import about from './../../assets/aboutUs.png';
import aboutUs from './aboutUsString';
import AwardsAndRecognition from './../../components/awardsRecognition/AwardsAndRecognition';
import callUs from './../../assets/callUs.png'
import chatWithUs from './../../assets/chatWithUs.png'
import email from './../../assets/emailUs.png'
import visit from './../../assets/visitUs.png'
import backButton from './../../assets/Backbutton2.png'
import { Link } from 'react-router-dom';
import Category from '../../components/Category';

const AboutUs = () => {

    const contactOptions = {
        call: {
            icon: callUs,
            bold: "Call us",
            light: "Call our team 24/7 for any queries.",
            contactDetail: ['+91 98581 81181'],
        },
        chat: {
            icon: chatWithUs,
            bold: "Chat with us",
            light: "Chat with our friendly team with live chat.",
            contactDetail: ['Start a live chat', 'Chat us on WhatsApp'],
        },
        email: {
            icon: email,
            bold: "Shoot us an email",
            light: "Shoot us an email anytime",
            contactDetail: ['medicabuyer@gmail.com'],
        },
        visitUs: {
            icon: visit,
            bold: "Visit us",
            light: 'Talk with us in person at ......',
            contactDetail: ['+91 98581 81181'],
        },
    };
    return (
        <>
            <Category />
            <section className="flex justify-center font-poppins">
                <div className='container px-6 py-12'>
                    <div className='w-full'>
                        <div className='flex items-center gap-4 py-4'>
                            <button className='text-[#848484] '>
                                <Link to="/" className='flex items-center gap-2'>
                                    <img src={backButton} alt="" /> Home
                                </Link>
                            </button>
                            <button className='text-[#848484] flex items-center gap-2'>
                                &gt; About Us
                            </button>
                        </div>
                        <div className="relative">
                            {/* Float the image to the left */}
                            <img
                                src={about}
                                alt="About Us"
                                className="float-left w-full  sm:w-1/2 lg:w-1/3 mr-6 mb-4"
                            />
                            <div className='text-sm md:text-base lg:text-lg xl:text-xl text-justify space-y-6'>
                                <h1 className="text-2xl xl:text-3xl font-semibold">{aboutUs.title}</h1>
                                <p className="mb-4">{aboutUs.para1}</p>
                                <p className="mb-4">{aboutUs.para2}</p>
                                <p>{aboutUs.para3}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div className='bg-[#EBF7F9] flex justify-center font-poppins'>
                <div className='container px-6 py-8'>
                    <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8 px-8'>
                        {/* Mapping over contact options */}
                        {Object.keys(aboutUs.contactOptions).map((key) => {
                            const { icon, bold, light, contactDetail } = aboutUs.contactOptions[key];
                            return (
                                <div key={key} className='text-center sm:text-left'>
                                    <img src={icon} alt={bold} className="mx-auto sm:mx-0 mb-4 w-12 h-12" />
                                    <h3 className='text-sm md:text-base xl:text-lg text-[#2F94A6]'>{bold}</h3>
                                    <p className='text-xs lg:text-sm  text-gray-500 mb-4'>{light}</p>
                                    {/* Contact details */}
                                    {contactDetail.map((detail, index) => (
                                        <div key={index} className="flex items-center  sm:justify-start justify-center gap-2">
                                            {detail.icon && <img src={detail.icon} alt="" className="w-6 h-6" />}
                                            <p className="text-[#000] font-semibold">{detail.text}</p>
                                        </div>
                                    ))}
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
            <AwardsAndRecognition />
        </>
    );
};

export default AboutUs;
