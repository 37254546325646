const cart = {
    title: "Your Cart",
    priceDetail: "Price Details",
    item: 'Item ',
    total: "Total Amount",
    gst: 'GST',
    grandTotal: 'Grand Total',
    proceedToPay: "Proceed to Pay",
    refurbishedProducts: {
        title: "Refurbished Products",
        littmann: {
            title: "3M Littmann Classic III Monitoring Inject...",
            price: "₹5599",
            crossPrice: '₹6599',
            addToCartBtn: "Add to Cart",
        },
    }
}
export default cart;