const bestSeller = {
    title: "Best Sellers",
    desc: "Discover a wide range of genuine medicines and health products online.",
    seeAll: "See all",
    price: "₹5599",
    crossPrice: '₹6599',
    off: "10% off",
    carePlus: {
        id: 1,
        title: "CarePlus Pharmacy",
        description: "Discover a wide range of genuine medicines and health products online.",
        getQuoteBtn: 'Get Quote',
        price: "₹5599",
        crossPrice: '₹6599',
        off: "10% off",
    },
    healthZone: {
        id: 2,
        title: "HealthZone Pharmacy",
        description: "Discover a wide range of genuine medicines and health products online.",
        getQuoteBtn: 'Get Quote',
        price: "₹5599",
        crossPrice: '₹6599',
        off: "10% off",
    },
    vitalHealth: {
        id: 3,
        title: "VitalHealth Supplies",
        description: "Discover a wide range of genuine medicines and health products online.",
        getQuoteBtn: 'Get Quote',
        price: "₹5599",
        crossPrice: '₹6599',
        off: "10% off",
    },
    drJessica: {
        id: 4,
        title: "Dr. Jessica Brown",
        description: "Discover a wide range of genuine medicines and health products online.",
        getQuoteBtn: 'Get Quote',
        price: "₹5599",
        crossPrice: '₹6599',
        off: "10% off",
    },
    bestSellerPage: {
        carePlus: {
            title: "CarePlus Pharmacy",
            description: "Discover a wide range of genuine medicines and health products online.",
            explore: "Explore",
        },
        healthZone: {
            title: "HealthZone Pharmacy",
            description: "Discover a wide range of genuine medicines and health products online.",
            explore: "Explore",
        },
        vitalHealth: {
            title: "VitalHealth Supplies",
            description: "Discover a wide range of genuine medicines and health products online.",
            explore: "Explore",
        },
        drJessica: {
            title: "Dr. Jessica Brown",
            description: "Discover a wide range of genuine medicines and health products online.",
            explore: "Explore",
        }
    }
}
export default bestSeller