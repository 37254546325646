const footer = {
    aboutUs: "About Us",
    contactUs: 'Contact Us',
    shopByCategory: "Shop by Category",
    medicalDevice: 'Medical Devices',
    medicalDressings: 'Medical Dressings',
    consumables: "Consumables",
    imagingSolutions: 'Imaging Solutions',
    medicalEquipment: "Medical Equipments",
    dialysisCare: 'Dialysis Care',
    sellWithUs: 'Sell with Us',
    policies: "Policies",
    returnpolicy: 'Returns and Refund Policy',
    privacypolicy: 'Privacy Policy',
    terms: "Terms of Use",
    shipping: "Shipping Policy",
    connectWithUs: 'Connect with us',
    copyright: "© Copyright 2024 -",
    medicaBuyer: "MedicaBuyer,",
    copyright2: " All Rights Reserved.",
}
export default footer