import React from 'react'
import bestSeller from '../../components/bestSeller/bestSellerString'
import bestSellerQuote from './../../assets/bestSellerQuote.png'
import CommonBigOffer from '../../components/bigOffer/CommonBigOffer'
import like from './../../assets/like (2).png'
import carePlus from './../../assets/carePlus.png'
import healthZone from './../../assets/healthZone.png'
import vitalHealth from './../../assets/vitalHealth.png'
import drJessica from './../../assets/drJessica.png'

const BestSellerPage = () => {
    return (
        <section className="flex justify-center font-poppins h-screen" >
            <div className='container px-6 py-12'>
                <div className='w-full text-center relative'>
                    <div className='absolute -top-4 left-1/2 transform -translate-x-1/2 '>
                        <img src={bestSellerQuote} alt="" className='w-max h-full' />
                    </div>
                    {/* <div className='text-center'> */}
                    <h2 className='font-semibold text-2xl xl:text-3xl'>{bestSeller.title}</h2>
                    <div className='text-[#848484] text-xs md:text-sm lg:text-base'>{bestSeller.desc}</div>
                    {/* </div> */}
                </div>
                <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4'>
                    <CommonBigOffer data={bestSeller.bestSellerPage.carePlus} img={carePlus} like={like} />
                    <CommonBigOffer data={bestSeller.bestSellerPage.healthZone} img={healthZone} like={like} />
                    <CommonBigOffer data={bestSeller.bestSellerPage.vitalHealth} img={vitalHealth} like={like} />
                    <CommonBigOffer data={bestSeller.bestSellerPage.drJessica} img={drJessica} like={like} />
                    <CommonBigOffer data={bestSeller.bestSellerPage.carePlus} img={carePlus} like={like} />
                    <CommonBigOffer data={bestSeller.bestSellerPage.healthZone} img={healthZone} like={like} />
                    <CommonBigOffer data={bestSeller.bestSellerPage.vitalHealth} img={vitalHealth} like={like} />
                    <CommonBigOffer data={bestSeller.bestSellerPage.drJessica} img={drJessica} like={like} />
                    <CommonBigOffer data={bestSeller.bestSellerPage.carePlus} img={carePlus} like={like} />
                    <CommonBigOffer data={bestSeller.bestSellerPage.healthZone} img={healthZone} like={like} />
                    <CommonBigOffer data={bestSeller.bestSellerPage.vitalHealth} img={vitalHealth} like={like} />
                    <CommonBigOffer data={bestSeller.bestSellerPage.drJessica} img={drJessica} like={like} />
                </div>
            </div>
        </section>
    )
}

export default BestSellerPage
