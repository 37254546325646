import React from 'react';
import vector2 from './../../assets/Vector2.png';
import review from './../../assets/review.png';
import grayStar from './../../assets/grayStar.png';
import yellowStar from './../../assets/yellowStar.png';

const CustomerReview = ({ text, img, name, rating }) => {
    // Array to hold 5 stars, showing yellow for the number of stars in rating, gray for the rest
    const stars = Array.from({ length: 5 }, (_, index) =>
        index < rating ? yellowStar : grayStar
    );

    return (
        <div className='px-4'>
            <div className='rounded-3xl bg-white overflow-hidden p-8'>
                <div className=''>
                    <img src={vector2} alt="" />
                </div>
                <div className='text-[#434343] py-2 text-xs md:text-sm lg:text-base'>{text}</div>
                <div className='flex items-center gap-2 h-12'>
                    <div className='h-full'>
                        <img src={img} alt="" className='h-10 w-12 rounded-full' crossOrigin="anonymous" />
                    </div>
                    <div>
                        <div className='font-semibold'>{name}</div>
                        <div className='flex items-center gap-1'>
                            {stars.map((star, index) => (
                                <img key={index} src={star} alt="star" className='w-4 h-4' />
                            ))}
                            {/* <div className='text-xs md:text-sm text-[#434343]'>{rating}</div> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CustomerReview;
