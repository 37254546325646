import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    firstName: '',
    lastName: '',
    email: '',
    mobile: '',
    message: ''
};

const contactUsSlice = createSlice({
    name: 'contactUs',
    initialState,
    reducers: {
        setFormData: (state, action) => {
            const { name, value } = action.payload;
            state[name] = value;
        },
        clearFormData: (state) => {
            return initialState; // Reset to the initial state
        }
    }
});

// Export actions
export const { setFormData, clearFormData } = contactUsSlice.actions;

// Export reducer
export default contactUsSlice.reducer;
