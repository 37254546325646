const signIn = {
    title: "Start buying in 4 Simple Steps:",
    steps: {
        "1": {
            title: "Sign In/Sign Up",
            desc: "Log in to your existing account or create a new one to access our platform.",
        },
        "2": {
            title: "Basic Information",
            desc: "Provide essential details to personalize your experience and ensure smooth transactions.",
        },
        "3": {
            title: "Complete KYC",
            desc: "Verify your identity by completing the Know Your Customer (KYC) process, ensuring a secure and trusted shopping experience.",
        },
        "4": {
            title: "Start Buying",
            desc: "Once your account is set up and verified, explore our products and start purchasing the medical supplies you need, quickly and easily.",
        }
    },
    createAccount: "Create an account",
    signIn: "Sign In an account",
    createAccountDesc: "Add below details to proceed creating your MedicaBuyer account.",
    email: "Email ID",
    emailPlaceHolder: "Enter Email ID",
    password: "Password",
    passwordPlaceHolder: "Enter Password",
    mobile: "Mobile No.",
    mobilePlaceHolder: "Enter mobile no.",
    notify: "Notify me on exclusive updates and special offers",
    createWithMobile: "Create with Mobile No.",
    signInWithMobile: "Sign In with Mobile No.",
    createWithGoogle: "Create with Google",
    signInWithGoogle: "Sign In with Google",
    createWithEmail: "Create with Email ID",
    signInWithEmail: "Sign In with Email ID",
    alreadyAccount: "Already have an account? ",
    logIn: 'Log In',
    accountSuccess: {
        title: "Account Activation Success!",
        description: "Congratulations! Your MedicaBuyer account is successfully activated with KYC completion. Enjoy exclusive offers, explore our platform, and shop with confidence. Stay updated on discounts, offers, and new arrivals—no need to worry, we’ll keep you informed! Happy shopping :)",
        btn: "Alright, redirect me to the homepage.",
    }
}
export default signIn