import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setFormData, clearFormData } from '../../reduxStore/contactUsSlice';
import contactus from './../../assets/contactUs.png';
import contactUs from './contactUs';
import aboutUs from './../aboutUs/aboutUsString';
import AwardsAndRecognition from './../../components/awardsRecognition/AwardsAndRecognition';
import Notification from './Notification';
import { contactUsForm } from '../../apiEndPoint';

const ContactUs = () => {
    const dispatch = useDispatch();
    const formData = useSelector((state) => state.contactUs);

    const [errors, setErrors] = useState({});
    const [notification, setNotification] = useState(false);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        dispatch(setFormData({ name, value }));
        setErrors((prev) => ({ ...prev, [name]: '' }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        let newErrors = {};

        // Validate inputs
        if (!formData.firstName) newErrors.firstName = 'First name is required';
        if (!formData.lastName) newErrors.lastName = 'Last name is required';
        if (!formData.email) newErrors.email = 'Email is required';

        const mobileRegex = /^[0-9]{10}$/;
        if (!formData.mobile) {
            newErrors.mobile = 'Mobile number is required';
        } else if (!mobileRegex.test(formData.mobile)) {
            newErrors.mobile = 'Mobile number must be exactly 10 digits';
        }

        if (!formData.message) newErrors.message = 'Message is required';

        // Check for errors
        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
        } else {
            try {
                console.log("Submitting contact form with data:", formData);
                const response = await contactUsForm(formData);
                console.log("Form submission successful:", response);
                setNotification(true);
                dispatch(clearFormData());
            } catch (error) {
                console.error("Error submitting contact form:", error);
                setErrors({ submit: "An error occurred while submitting your inquiry." });
            }
        }
    };


    const handleGoBack = () => {
        setNotification(false);
    };

    return (
        <>
            <section className="flex justify-center font-poppins">
                <div className="container px-6 py-12 grid grid-cols-1 md:grid-cols-5 gap-4 md:gap-8 lg:gap-12 xl:gap-16 h-min">
                    <div className="col-span-2">
                        <img src={contactus} alt="Contact Us" className="w-full h-full" />
                    </div>
                    <div className="w-full col-span-3 flex flex-col justify-between">
                        <div className="w-full">
                            <h2 className="font-semibold text-2xl xl:text-3xl">{contactUs.title}</h2>
                            <div className="text-[#848484] text-xs lg:text-sm">{contactUs.desc}</div>
                        </div>
                        <div className="flex justify-between items-center gap-4">
                            {/* First Name Input */}
                            <div className="py-2 w-full">
                                <div className="text-[#848484]">First Name</div>
                                <input
                                    type="text"
                                    name="firstName"
                                    value={formData.firstName}
                                    onChange={handleInputChange}
                                    placeholder="Enter first name"
                                    className={`text-[#848484]/50 bg-[#F3F4F4] rounded-2xl p-4 w-full outline-none ${errors.firstName ? 'border border-red-500' : ''}`}
                                />
                                {errors.firstName && (
                                    <p className="text-red-500 text-xs">{errors.firstName}</p>
                                )}
                            </div>
                            {/* Last Name Input */}
                            <div className="py-2 w-full">
                                <div className="text-[#848484]">Last Name</div>
                                <input
                                    type="text"
                                    name="lastName"
                                    value={formData.lastName}
                                    onChange={handleInputChange}
                                    placeholder="Enter last name"
                                    className={`text-[#848484]/50 bg-[#F3F4F4] rounded-2xl p-4 w-full outline-none ${errors.lastName ? 'border border-red-500' : ''}`}
                                />
                                {errors.lastName && (
                                    <p className="text-red-500 text-xs">{errors.lastName}</p>
                                )}
                            </div>
                        </div>
                        {/* Email Input */}
                        <div className="py-2">
                            <div className="text-[#848484]">Email ID</div>
                            <input
                                type="email"
                                name="email"
                                value={formData.email}
                                onChange={handleInputChange}
                                placeholder="Enter Email ID"
                                className={`text-[#848484]/50 bg-[#F3F4F4] rounded-2xl p-4 w-full outline-none ${errors.email ? 'border border-red-500' : ''}`}
                            />
                            {errors.email && (
                                <p className="text-red-500 text-xs">{errors.email}</p>
                            )}
                        </div>
                        {/* Mobile Input */}
                        <div className="py-2">
                            <div className="text-[#848484]">Mobile No.</div>
                            <input
                                type="text"
                                name="mobile"
                                value={formData.mobile}
                                onChange={handleInputChange}
                                placeholder="Enter Mobile No."
                                className={`text-[#848484]/50 bg-[#F3F4F4] rounded-2xl p-4 w-full outline-none ${errors.mobile ? 'border border-red-500' : ''}`}
                            />
                            {errors.mobile && (
                                <p className="text-red-500 text-xs">{errors.mobile}</p>
                            )}
                        </div>
                        {/* Message Input */}
                        <div className="py-2">
                            <div className="text-[#848484]">Your Message</div>
                            <textarea
                                name="message"
                                value={formData.message}
                                onChange={handleInputChange}
                                placeholder="Write your message here"
                                className={`text-[#848484]/50 bg-[#F3F4F4] rounded-2xl p-4 w-full outline-none ${errors.message ? 'border border-red-500' : ''}`}
                            />
                            {errors.message && (
                                <p className="text-red-500 text-xs">{errors.message}</p>
                            )}
                        </div>
                        {/* Submit Button */}
                        <div className="text-sm lg:text-base xl:text-lg text-right">
                            <button
                                type="submit"
                                className="bg-[#1A444C] inline-block rounded-full p-2 lg:p-4 text-white"
                                onClick={handleSubmit}
                            >
                                Send Inquiry
                            </button>
                        </div>
                    </div>
                </div>
            </section>
            <div className=' flex justify-center font-poppins py-8 pb-20'>
                <div className='container px-6 py-8'>
                    <div className='border border-[#84848426] rounded-2xl p-12 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8 px-8'>
                        {/* Mapping over contact options */}
                        {Object.keys(aboutUs.contactOptions).map((key) => {
                            const { icon, bold, light, contactDetail } = aboutUs.contactOptions[key];
                            return (
                                <div key={key} className='text-center sm:text-left'>
                                    <img src={icon} alt={bold} className="mx-auto sm:mx-0 mb-4 w-12 h-12" />
                                    <h3 className='text-sm md:text-base xl:text-lg text-[#2F94A6]'>{bold}</h3>
                                    <p className='text-xs lg:text-sm  text-gray-500 mb-4'>{light}</p>
                                    {/* Contact details */}
                                    {contactDetail.map((detail, index) => (
                                        <div key={index} className="flex items-center  sm:justify-start justify-center gap-2">
                                            {detail.icon && <img src={detail.icon} alt="" className="w-6 h-6" />}
                                            <p className="text-[#000] font-semibold">{detail.text}</p>
                                        </div>
                                    ))}
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
            {notification &&
                <Notification onClose={handleGoBack} text="We’ve received your inquiry message. Thank you for reaching out to us. Admin will contact you soon." />
            }
        </>
    );
};

export default ContactUs;
