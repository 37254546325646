// subCategorySlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchSubCategories } from './../apiEndPoint'; // Ensure your API function is correctly imported

// Async thunk to fetch subcategories
export const fetchSubCategoriesAsync = createAsyncThunk(
    'subCategories/fetchSubCategories',
    async () => {
        const response = await fetchSubCategories();
        return response; // Return the data received from the API
    }
);

const subCategorySlice = createSlice({
    name: 'subCategories',
    initialState: {
        items: [],        // Array to hold subcategory data
        loading: false,   // Loading status
        error: null,      // Error message
        initialTitle: localStorage.getItem("initialTitle") || "", // Retrieve from local storage on init
    },
    reducers: {
        // Action to set initialTitle
        setInitialTitle: (state, action) => {
            state.initialTitle = action.payload;
            localStorage.setItem("initialTitle", action.payload); // Save to local storage
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchSubCategoriesAsync.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchSubCategoriesAsync.fulfilled, (state, action) => {
                state.loading = false;
                state.items = action.payload; // Set the fetched subcategories in items
            })
            .addCase(fetchSubCategoriesAsync.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message; // Set error if fetching fails
            });
    },
});

// Export actions and reducer
export const { setInitialTitle } = subCategorySlice.actions;
export default subCategorySlice.reducer;
