import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  setName,
  setIdNumber,
  setKYCProof,
  uploadDocument,
  showActivationSuccess,
  toggleNotify,
} from "./../../../reduxStore/kycSlice";
import uploadFile from "./../../../assets/UploadFile.png";
import uploadPhoto from "./../../../assets/uploadPhoto.png";
import {
  hideCreateAccount,
  hideSignInAccount,
} from "../../../reduxStore/modalSlice";
import ActivationSuccess from "./ActivationSuccess";
import signIn from "../signInString";

const KYCForm = ({ title }) => {
  const dispatch = useDispatch();
  const { name, idNumber, kycProof, document, notify } = useSelector(
    (state) => state.kyc
  );
  const activationSuccessForm = useSelector(
    (state) => state.kyc.activationSuccessForm
  );

  // Determine if the buttons should be disabled
  const isDisabled = !notify;

  // Local state to store actual File objects
  const [kycProofFile, setKYCProofFile] = useState(null);
  const [documentFile, setDocumentFile] = useState(null);

  // State to hold the preview URLs
  const [kycProofPreview, setKYCProofPreview] = useState(null);
  const [documentPreview, setDocumentPreview] = useState(null);

  const handleNameChange = (e) => dispatch(setName(e.target.value));
  const handleIdChange = (e) => dispatch(setIdNumber(e.target.value));

  const handleProofChange = (e) => {
    const file = e.target.files[0];
    setKYCProofFile(file);
    const previewURL = URL.createObjectURL(file);
    setKYCProofPreview(previewURL);
    dispatch(setKYCProof(previewURL)); // Store preview URL in Redux
  };

  const handleDocumentChange = (e) => {
    const file = e.target.files[0];
    setDocumentFile(file);
    const previewURL = URL.createObjectURL(file);
    setDocumentPreview(previewURL);
    dispatch(uploadDocument(previewURL)); // Store preview URL in Redux
  };

  const handleSubmit = (e) => {
    e.preventDefault(); // Prevent the default form submission behavior
    console.log("Form Submitted:", {
      name,
      idNumber,
      kycProof,
      document,
      notify,
    });
    dispatch(showActivationSuccess());
  };

  const handleCloseSignin = () => {
    dispatch(hideCreateAccount());
    dispatch(hideSignInAccount());
  };

  // Clear previous URL object to avoid memory leaks
  const clearPreview = (previewSetter) => {
    previewSetter((prevPreview) => {
      if (prevPreview) {
        URL.revokeObjectURL(prevPreview);
      }
      return null;
    });
  };

  // Handling when the component is unmounted to prevent memory leaks
  useEffect(() => {
    return () => {
      clearPreview(setKYCProofPreview);
      clearPreview(setDocumentPreview);
    };
  }, []);

  return (
    <div className="">
      <div>
        <h2 className="bg-[#EBF7F9] p-2 md:p-4 rounded-2xl font-semibold text-base lg:text-lg xl:text-xl 2xl:text-2xl">
          Your selected KYC Proof: {title}
        </h2>
      </div>
      {/* // Replace <form onSubmit={handleSubmit}> with <div> */}
      <div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 p-4 md:p-8">
          <div className="py-2 col-span-2">
            <div className="text-[#848484]">Your Name</div>
            <input
              type="text"
              placeholder="Enter name mentioned in ID"
              value={name}
              onChange={handleNameChange}
              className="bg-[#F3F4F4] rounded-2xl p-4 w-full outline-none"
            />
          </div>
          <div className="py-2 col-span-2">
            <div className="text-[#848484]">
              ID Number (Please upload Student ID/Employee ID Number)
            </div>
            <input
              type="text"
              placeholder="eg. 15as4a8x"
              value={idNumber}
              onChange={handleIdChange}
              className="bg-[#F3F4F4] rounded-2xl p-4 w-full outline-none"
            />
          </div>
          <div className="py-2 col-span-2">
            <div className="text-[#848484]">
              Upload Proof (The document should contain above mentioned name)
            </div>
            <div className="flex items-center gap-1">
              <label className="bg-[#F3F4F4] rounded-2xl p-4 w-full outline-none text-[#848484]/50 h-[15rem] flex justify-center items-center gap-2 cursor-pointer">
                {kycProofPreview ? (
                  <img
                    src={kycProofPreview}
                    alt="KYC Proof Preview"
                    className="rounded-lg w-full h-full"
                  />
                ) : (
                  <div className="flex flex-col justify-around">
                    <img src={uploadFile} alt="" />
                    <span>{kycProof ? kycProof.name : "Choose a file"}</span>
                  </div>
                )}
                <input
                  type="file"
                  className="hidden"
                  onChange={handleProofChange}
                />
              </label>
              <div>OR</div>
              <label className="bg-[#F3F4F4] rounded-2xl p-4 w-full outline-none text-[#848484]/50 h-[15rem] flex justify-center items-center gap-2 cursor-pointer">
                {documentPreview ? (
                  <img
                    src={documentPreview}
                    alt="Document Preview"
                    className="rounded-lg w-full h-full"
                  />
                ) : (
                  <div className="flex flex-col justify-around">
                    <img src={uploadPhoto} alt="" />
                    <span>{document ? document.name : "Take a photo"}</span>
                  </div>
                )}
                <input
                  type="file"
                  className="hidden"
                  onChange={handleDocumentChange}
                />
              </label>
            </div>
          </div>
          <div className="flex items-center gap-2 pb-4 col-span-2">
            <input
              type="checkbox"
              checked={notify}
              onChange={() => dispatch(toggleNotify())}
            />
            <p>{signIn.notify}</p>
          </div>
        </div>
        <div className="text-base lg:text-lg flex justify-end px-4 md:px-8">
          <button
            type="button" // Change to "button" to avoid any form submission behavior
            className={`bg-[#1A444C] inline rounded-full px-4 py-2 text-white ${isDisabled ? "opacity-50 cursor-not-allowed" : ""
              }`}
            onClick={handleSubmit} // Attach your handleSubmit function here
            disabled={isDisabled}
          >
            Complete Verification
          </button>
        </div>
        {activationSuccessForm && (
          <ActivationSuccess onClose={handleCloseSignin} title={signIn.accountSuccess.title} text={signIn.accountSuccess.description} />
        )}
      </div>
    </div>
  );
};

export default KYCForm;
