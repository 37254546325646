const shopByCategory = {
    title: 'Shop by Category',
    desc: 'Discover a wide range of genuine medicines and health products online.',
    seeAll: "See all",
    medicalDevice: "Medical Devices",
    mriMachine: "MRI Machines",
    xRayMachine: 'X-ray Machines',
    ecgMachine: 'ECG Machines',
    endoScopySystem: "Endoscopy System",
    medicalDressing: "Medical Dressings",
    consumables: 'Consumables',
    imagingSolutions: "Imaging Solutions",
    medicalEquipments: "Medical Equipments",
    dialysisCare: "Dialysis Care",
    subCategoryTitle: "Sub Categories of ",
    medicalDeviceArray: {
        thermoMeter: {
            title: "Thermometer Classic III Fever Monitoring Doctor sp...",
            price: "₹599",
            crossPrice: "₹899",
            off: "10% off",
            viewDetails: "View Details",
        },
        bloodPressure: {
            title: "Blood Pressure Monitor | Doctor’s choice | No. 1 Selling...",
            price: "₹2500",
            crossPrice: "₹3599",
            off: "10% off",
            viewDetails: "View Details",
        },
        littmann: {
            title: "3M Littmann Classic III Monitoring Inject....",
            price: "₹5599",
            crossPrice: "₹6599",
            off: "10% off",
            viewDetails: "View Details",
        },
        syring: {
            title: "Syringe | 3M Littmann Classic III Monitoring Inject....",
            price: "₹399",
            crossPrice: "₹599",
            off: "10% off",
            viewDetails: "View Details",
        },
    },
}
export default shopByCategory