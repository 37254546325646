import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    name: '',
    idNumber: '',
    kycProof: '',
    document: '',
    notify: false,
    activationSuccessForm: false,
};

const kycSlice = createSlice({
    name: 'kyc',
    initialState,
    reducers: {
        setName: (state, action) => {
            state.name = action.payload;
        },
        setIdNumber: (state, action) => {
            state.idNumber = action.payload;
        },
        setKYCProof: (state, action) => {
            state.kycProof = action.payload;
        },
        uploadDocument: (state, action) => {
            state.document = action.payload;
        },
        toggleNotify(state) {
            state.notify = !state.notify;
        },
        showActivationSuccess: (state) => {
            state.activationSuccessForm = (true);
        },
    },
});

export const { setName, setIdNumber, setKYCProof, uploadDocument, toggleNotify, showActivationSuccess } = kycSlice.actions;
export default kycSlice.reducer;
