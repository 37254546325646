import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchCustomerReviews } from "./../../reduxStore/customerReviewsSlice";
import Slider from "react-slick";
import { GrLinkNext, GrLinkPrevious } from "react-icons/gr";
import CustomerReview from "./CustomerReview";
import vector from "./../../assets/Vector.png";

// Custom Arrow Components
const PrevArrow = ({ onClick }) => (
  <button
    onClick={onClick}
    className="absolute -top-[35%] sm:-top-[10%] left-[70%] sm:left-[85%] md:left-[87%] lg:left-[90%] xl:left-[92%] 2xl:left-[94%] transform -translate-y-1/2 bg-white p-3 rounded-full shadow-lg z-10 hover:bg-[#1A444C] text-[#1A444C] hover:text-[#E1DFD7]"
    aria-label="Previous"
  >
    <GrLinkPrevious />
  </button>
);

const NextArrow = ({ onClick }) => (
  <button
    onClick={onClick}
    className="absolute right-0 -top-[35%] sm:-top-[10%] transform -translate-y-1/2 bg-white p-3 rounded-full shadow-lg z-10 hover:bg-[#1A444C] text-[#1A444C] hover:text-[#E1DFD7]"
    aria-label="Next"
  >
    <GrLinkNext />
  </button>
);

const CustomerSays = () => {
  const dispatch = useDispatch();
  const { reviews, loading, error } = useSelector(
    (state) => state.customerReviews
  );

  useEffect(() => {
    dispatch(fetchCustomerReviews());
  }, [dispatch]);

  const settings = {
    dots: false,
    infinite: true,
    speed: 300,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      { breakpoint: 1024, settings: { slidesToShow: 3 } },
      { breakpoint: 768, settings: { slidesToShow: 2 } },
      { breakpoint: 640, settings: { slidesToShow: 1 } },
    ],
  };

  if (error) return <div>Error: {error}</div>; // Show error if fetch fails

  return (
    <section className="flex justify-center bg-[#EBF7F9] font-poppins">
      <div className="container px-6 py-8">
        <div className="relative w-full text-left pt-14">
          <div className="absolute top-0">
            <img src={vector} alt="Decoration" />
          </div>
          <h1 className="font-semibold text-2xl xl:text-3xl">
            What Our Customers Say
          </h1>
          <div className="text-[#848484] text-xs md:text-sm lg:text-base">
            Hear from some of our happy customers
          </div>
        </div>

        <div className="py-4">
          {reviews.length > 3 ? (
            <Slider {...settings}>
              {reviews.map((review, index) => (
                <CustomerReview
                  key={index}
                  img={`${process.env.REACT_APP_BASE_URL}${review.image}`}
                  text={review.review}
                  name={review.name}
                  rating={review.rating}
                />
              ))}
            </Slider>
          ) : (
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
              {reviews.map((review, index) => (
                <CustomerReview
                  key={index}
                  img={`${process.env.REACT_APP_BASE_URL}${review.image}`}
                  text={review.review}
                  name={review.name}
                  rating={review.rating}
                />
              ))}
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default CustomerSays;
