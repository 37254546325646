import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchCategories as fetchCategoriesAPI } from '../apiEndPoint';

export const fetchCategories = createAsyncThunk(
    'categories/fetchCategories',
    async () => {
        const data = await fetchCategoriesAPI();
        return data; // Directly return the fetched data (no need to call .json())
    }
);

const categorySlice = createSlice({
    name: 'categories',
    initialState: {
        items: [],
        status: 'idle',
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchCategories.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchCategories.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.items = action.payload; // Store the fetched categories
            })
            .addCase(fetchCategories.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            });
    },
});

export default categorySlice.reducer;
