import React, { useEffect, useState } from 'react';
import cart from './cartString';
import cartIcon from './../../assets/Cart.png';
import subProduct from './../../assets/subProductImage1.png';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { decrementQuantity, deleteCartItem, fetchCartItems, incrementQuantity } from '../../reduxStore/cartSlice';
import minus from './../../assets/minus.png';
import deleteIcon from './../../assets/Delete.png';
import plus from './../../assets/plus.png';
import ibutton from './../../assets/iButton.png';
import like from './../../assets/like (2).png'
import alsoBuy1 from './../../assets/alsoBuy1.png';
import alsoBuy2 from './../../assets/alsoBuy2.png';
import alsoBuy3 from './../../assets/alsoBuy3.png';
import alsoBuy4 from './../../assets/alsoBuy4.png';
import alsoBuy5 from './../../assets/alsoBuy5.png';
import alsoBuy6 from './../../assets/alsoBuy6.png';
import CommonBigOffer from '../../components/bigOffer/CommonBigOffer';
import { deleteFromCart, fetchRefurbishedProducts } from '../../apiEndPoint';
import shopByCategory from '../../components/shopByCategory/shopByCategoryString';
import { GrLinkNext, GrLinkPrevious } from 'react-icons/gr';
import Slider from 'react-slick';

// Custom Arrow Components
const PrevArrow = ({ onClick }) => {
    return (
        <button
            onClick={onClick}
            className="absolute left-0 top-[40%] sm:top-[36%] md:top-[31%] xl:top-[35%] 2xl:top-[37%] transform -translate-y-1/2 bg-white p-3 rounded-full shadow-lg z-10 hover:bg-[#1A444C] text-[#1A444C] hover:text-[#E1DFD7]"
            aria-label="Previous"
        >
            <GrLinkPrevious className='' />
        </button>
    );
};

const NextArrow = ({ onClick }) => {
    return (
        <button
            onClick={onClick}
            className="absolute right-0 top-[40%] sm:top-[36%] md:top-[31%] xl:top-[35%] 2xl:top-[37%] transform -translate-y-1/2 bg-white p-3 rounded-full shadow-lg z-10 hover:bg-[#1A444C] text-[#1A444C] hover:text-[#E1DFD7]"
            aria-label="Next"
        >
            <GrLinkNext />
        </button>
    );
};

const Cart = () => {
    const location = useLocation();
    const [refurbished, setRefurbished] = useState([]);
    const { price, crossPrice, off, title } = location.state || {}; // Fallback if location.state is undefined

    const dispatch = useDispatch();
    const { items, quantity, status, error } = useSelector((state) => state.cart);


    useEffect(() => {
        dispatch(fetchCartItems());
    }, [dispatch]);
    useEffect(() => {
        console.log('Cart items:', items); // Debugging
    }, [items]);
    const getDiscountedPrice = (price, discount) => {
        return Math.floor(parseFloat(price || 0) - (parseFloat(price || 0) * (parseFloat(discount || 0) / 100)));
    };
    // Function to calculate the total discounted price
    const calculateTotalDiscountedPrice = (items) => {
        return items.reduce((total, item) => {
            const discountedPrice = getDiscountedPrice(item.productId.variation[0].price, item.productId.variation[0].discount);
            return total + discountedPrice;
        }, 0);
    };

    // Calculate the total discounted price for all items
    const totalDiscountedPrice = items && items.items ? calculateTotalDiscountedPrice(items.items) : 0;

    const gstAmt = totalDiscountedPrice * 0.18;
    const grandTotalAmout = totalDiscountedPrice - gstAmt;

    useEffect(() => {
        const fetchRefurbishedProductDate = async () => {
            try {
                const response = await fetchRefurbishedProducts();
                setRefurbished(response);
                console.log(response);
            } catch (error) {
                console.log(error);
            }
        }
        fetchRefurbishedProductDate();
    }, [])

    const handleIncrement = () => {
        dispatch(incrementQuantity());
    };

    const handleDecrement = () => {
        if (quantity > 1) {
            dispatch(decrementQuantity());
        }
    };
    const handleDeleteProduct = (id) => {
        dispatch(deleteCartItem(id));
    };

    if (status === 'loading') return <p>Loading...</p>;
    if (status === 'failed') return <p>Error: {error}</p>;

    // Slick slider settings
    const settings = {
        dots: false, // Show dots below the slider
        infinite: true, // Enable infinite loop
        speed: 300, // Animation speed
        slidesToShow: 4, // Number of slides to show at once
        slidesToScroll: 1, // Number of slides to scroll at once
        autoplay: true, // Enable autoplay
        autoplaySpeed: 2000, // Autoplay interval in ms
        nextArrow: <NextArrow />, // Custom Next Arrow
        prevArrow: <PrevArrow />, // Custom Prev Arrow
        responsive: [
            {
                breakpoint: 1536, // Screen width <= 1536px
                settings: {
                    slidesToShow: 4,
                },
            },
            {
                breakpoint: 1280, // Screen width <= 1280px
                settings: {
                    slidesToShow: 4,
                },
            },
            {
                breakpoint: 1024, // Screen width <= 1024px
                settings: {
                    slidesToShow: 3,
                },
            },
            {
                breakpoint: 768, // Screen width <= 768px
                settings: {
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 640, // Screen width <= 640px
                settings: {
                    slidesToShow: 1,
                },
            },
        ],
    };

    // const priceValue = parseInt(price.replace(/[^\d]/g, ''), 10); // Extract number from string
    // const totalQntPrice = priceValue * quantity;
    // const gstAmount = Math.round(totalQntPrice * 0.18);

    return (
        <section className="flex justify-center font-poppins">
            <div className='container px-6 py-12 h-auto min-h-screen'>
                <div className='w-full flex justify-between items-center '>
                    <div className='flex items-center gap-2'>
                        <img src={cartIcon} alt="Cart Icon" />
                        <h2 className='font-semibold text-2xl xl:text-3xl'>{cart.title}</h2>
                    </div>
                </div>
                <div className='w-full grid grid-cols-1 lg:grid-cols-5 gap-4 py-4 '>
                    <div className='lg:col-span-3 flex flex-col justify-between gap-2'>
                        {items && items.items && items.items.length > 0 ? (
                            items.items.map((item, index) => {
                                // Calculate the discounted price once here
                                const discountedPrice = getDiscountedPrice(item.productId.variation[0].price, item.productId.variation[0].discount);
                                return (
                                    <div key={index} className='rounded-xl bg-[#717171]/10 p-6'>
                                        <div className='h-min grid grid-cols-10 gap-4'>
                                            <div className='col-span-3'>
                                                <img src={`${process.env.REACT_APP_BASE_URL}/${item.productId.image[0]}`} alt="Product" className='w-full h-auto' />
                                            </div>
                                            <div className='flex w-full justify-between col-span-7'>
                                                <div>
                                                    <div className='text-sm md:text-base lg:text-lg'>
                                                        {item.productId?.name || ""}
                                                    </div>
                                                    <div className='flex items-center pt-2 gap-1'>
                                                        <p className='text-[#EF2D2D] font-semibold text-base md:text-lg lg:text-xl'>
                                                            {discountedPrice}
                                                        </p>
                                                        <p className='text-[#848484] text-sm md:text-base lg:text-lg line-through'>{item.productId.variation[0].price}</p>
                                                        <p className='text-[#43B678] text-sm md:text-base lg:text-lg'>{`${item.productId.variation[0].discount}% off`}</p>
                                                    </div>
                                                    <div className='flex items-center gap-2 p-1 text-[#848484]'>
                                                        <div className='bg-[#E0F1F6] rounded-lg flex items-center gap-2 p-1'>
                                                            <div>Qty</div>
                                                            <div className='flex items-center gap-2'>
                                                                <button onClick={() => handleDecrement(item)} className=''>
                                                                    <img src={minus} alt="Decrease Quantity" />
                                                                </button>
                                                                <div>{item.quantity}</div>
                                                                <button onClick={() => handleIncrement(item)} className=''>
                                                                    <img src={plus} alt="Increase Quantity" />
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className=''>
                                                    <img src={deleteIcon} alt="Delete Icon" className='cursor-pointer' onClick={() => handleDeleteProduct(item.productId?._id)} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })
                        ) : (
                            <p>No items in the cart.</p>
                        )}
                    </div>
                    <div className='lg:col-span-2'>
                        <div className='border-[#71717126] border py-1 lg:py-2 rounded-xl'>
                            <div className='border-b border-[#71717126] p-3 text-[#2F94A6]'>{cart.priceDetail}</div>
                            <div className='p-3 w-full'>
                                <div className='border-b border-[#71717126] w-full'>
                                    {items && items.items && items.items.length > 0 ? (
                                        items.items.map((item, index) => {
                                            // Calculate the discounted price once here
                                            const discountedPrice = getDiscountedPrice(item.productId.variation[0].price, item.productId.variation[0].discount);

                                            return (
                                                <div className='flex items-center justify-between py-2'>
                                                    <div>{cart.item}{index + 1}</div>
                                                    <div>₹{discountedPrice}</div>
                                                </div>
                                            );
                                        })) : (
                                        <></>
                                    )}
                                </div>
                                <div className='border-b border-[#71717126] w-full'>
                                    <div className='flex items-center justify-between pt-2'>
                                        <div>{cart.total}</div>
                                        <div>₹{totalDiscountedPrice}</div>
                                    </div>
                                    <div className='flex items-center justify-between py-2'>
                                        <div className='flex items-center gap-1'>
                                            {cart.gst}
                                            <img src={ibutton} alt="Info Button" />
                                        </div>
                                        <div>₹{gstAmt}</div>
                                    </div>
                                </div>
                                <div className='w-full'>
                                    <div className='flex items-center justify-between pb-8 py-2'>
                                        <div className='font-semibold uppercase'>{cart.grandTotal}</div>
                                        <div className='text-[#EF2D2D] font-semibold text-sm md:text-base lg:text-lg'>
                                            ₹{grandTotalAmout}
                                        </div>
                                    </div>
                                    <div>
                                        <button className='hover:bg-[#1A444C] border border-[#1A444C] rounded-lg p-2 w-full font-semibold hover:text-white'>
                                            {cart.proceedToPay}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='w-full py-8'>
                    <div>
                        <h2 className='font-semibold text-2xl xl:text-3xl'>{cart.refurbishedProducts.title}</h2>
                    </div>
                    {/* Slick Slider Section */}
                    {error ? (
                        <p className="text-red-500">Error loading products: {error}</p>
                    ) : refurbished.length === 0 ? (
                        <p>No products available at the moment.</p>
                    ) : (
                        <div className="py-4">
                            {refurbished.length > 3 ? (
                                <Slider {...settings} className="relative">
                                    {refurbished.map((product) => (
                                        <CommonBigOffer
                                            key={product._id}
                                            like={like}
                                            img={`${process.env.REACT_APP_BASE_URL}/${product.image}`}
                                            name={product.name}
                                            price={product.variation[0].price}
                                            off={`${product.variation[0].discount}% off`}
                                            data={shopByCategory.medicalDeviceArray.thermoMeter}
                                            dataOrg={product}
                                        />
                                    ))}
                                </Slider>
                            ) : (
                                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
                                    {refurbished.map((product) => (
                                        <CommonBigOffer
                                            key={product._id}
                                            like={like}
                                            img={`${process.env.REACT_APP_BASE_URL}/${product.image}`}
                                            name={product.name}
                                            price={product.variation[0].price}
                                            off={`${product.variation[0].discount}% off`}
                                            data={shopByCategory.medicalDeviceArray.thermoMeter}
                                            dataOrg={product}
                                        />
                                    ))
                                    }
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </div>
        </section>
    );
};

export default Cart;
