import React from 'react'
import { useNavigate } from 'react-router-dom';

const SubCategoryComponent = ({ img, name, isSelected }) => {
    const navigate = useNavigate();
    const handleSubCategoryComponent = () => {
        navigate("/subCategory", { state: { subCategoryName: name } })
    }
    return (
        <button onClick={handleSubCategoryComponent} className={`w-full relative transition-transform duration-600 ${isSelected ? 'scale-105 bg-[#d2f1f7] p-2 rounded-xl' : ''}`}>
            <div className='rounded-full overflow-hidden '>
                <img src={img} alt="" className='w-full rounded-full h-40' crossOrigin="anonymous" />
            </div>
            <div className='text-center py-2 text-base md:text-lg xl:text-lg '>{name}</div>
        </button>
    )
}

export default SubCategoryComponent
