import callUs from './../../assets/callUs.png'
import chatWithUs from './../../assets/chatWithUs.png'
import email from './../../assets/chatWithUs.png'
import visit from './../../assets/visitUs.png'
import whatsApp from './../../assets/whatsAppIcon.png'
import liveChat from './../../assets/liveChatIcon.png'
const aboutUs = {
    title: "About Us",
    para1: "The MedicaBuyer event organized by IMR Media (Organizer) will take place at the Manekshaw Centre, New Delhi on  28-29 Nov 2024. In case of circumstances beyond the control of the Organizers (referred to as force majeure), the Organizer may choose to change the  dates or the venue of this event or cancel it after notifying the participants. The MedicaBuyer event organized by IMR Media (Organizer) will take place at the Manekshaw Centre, New Delhi on  28-29 Nov 2024. In case of circumstances beyond the control of the Organizers (referred to as force majeure), the Organizer may choose to change the  dates or the venue of this event or cancel it after notifying the participants.",
    para2: "The MedicaBuyer event organized by IMR Media (Organizer) will take place at the Manekshaw Centre, New Delhi on  28-29 Nov 2024. In case of circumstances beyond the control of the Organizers (referred to as force majeure), the Organizer may choose to change the  dates or the venue of this event or cancel it after notifying the participants.",
    para3: "The MedicaBuyer event organized by IMR Media (Organizer) will take place at the Manekshaw Centre, New Delhi on  28-29 Nov 2024. In case of circumstances beyond the control of the Organizers (referred to as force majeure), the Organizer may choose to change the  dates or the venue of this event or cancel it after notifying the participants.",
    contactOptions: {
        call: {
            icon: callUs,
            bold: "Call us",
            light: "Call our team 24/7 for any queries.",
            contactDetail: [
                { text: '+91 98581 81181' },
            ],
        },
        chat: {
            icon: chatWithUs,
            bold: "Chat with us",
            light: "Chat with our friendly team with live chat.",
            contactDetail: [
                { icon: liveChat, text: 'Start a live chat' },
                { icon: whatsApp, text: 'Chat us on WhatsApp' },
            ],
        },
        email: {
            icon: email,
            bold: "Shoot us an email",
            light: "Shoot us an email anytime",
            contactDetail: [
                { text: 'medicabuyer@gmail.com' },
            ],
        },
        visitUs: {
            icon: visit,
            bold: "Visit us",
            light: 'Talk with us in person at ......',
            contactDetail: [
                { text: '+91 98581 81181' },
            ],
        },
    }
}
export default aboutUs