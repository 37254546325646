// src/store/awardsSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchAwardsAndRecognition } from '../apiEndPoint';

// Async thunk to fetch data
export const getAwardsAndRecognition = createAsyncThunk(
    'awards/getAwardsAndRecognition',
    async (_, { rejectWithValue }) => {
        try {
            const data = await fetchAwardsAndRecognition();
            return data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

const awardsSlice = createSlice({
    name: 'awards',
    initialState: {
        data: [],
        loading: false,
        error: null
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getAwardsAndRecognition.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(getAwardsAndRecognition.fulfilled, (state, action) => {
                state.loading = false;
                state.data = action.payload;
            })
            .addCase(getAwardsAndRecognition.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            });
    }
});

export default awardsSlice.reducer;
