const newArrivals = {
    title: "New Arrivals",
    desc: 'Discover a wide range of genuine medicines and health products online.',
    seeAll: "See all",
    medicalEquipment: {
        title: "Medical Equipment",
        description: "Discover a wide range of genuine medicines and health products online.",
        getQuoteBtn: 'Get Quote',
    },
    dialysisUnit: {
        title: "Dialysis Unit",
        description: "Discover a wide range of genuine medicines and health products online.",
        price: "₹5599",
        crossPrice: '₹6599',
        off: "10% off",
        buyNow: "Buy Now",
        addToCart: "Add to Cart",
    },
    consumables: {
        title: "Consumables",
        description: 'Discover a wide range of genuine medicines and health products online.',
        getQuoteBtn: 'Get Quote',
    },
    firstAidSupply: {
        title: "First Aid Supplies",
        description: "Discover a wide range of genuine medicines and health products online.",
        getQuoteBtn: "Get Quote",
    },
}
export default newArrivals