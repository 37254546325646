const bigOffer = {
    title: "Big Offers",
    seeAll: "See all",
    injections: {
        id: 1,
        title: "Injections",
        description: "Discover a wide range of genuine medicines and health products online.",
        price: "₹5599",
        crossPrice: '₹6599',
        off: "10% off",
        buyNow: "Buy Now",
        addToCart: "Add to Cart",
        discountLabel: "Flat 25% off",
    },
    bandages: {
        id: 2,
        title: "Bandages",
        description: "Discover a wide range of genuine medicines and health products online.",
        price: '₹5599',
        crossPrice: "₹6599",
        off: '10% off',
        buyNow: "Buy Now",
        addToCart: "Add to Cart",
        discountLabel: "Upto 35% off",
    },
    surgicalGloves: {
        id: 3,
        title: 'Surgical gloves',
        description: "Discover a wide range of genuine medicines and health products online.",
        getQuoteBtn: 'Get Quote',
        discountLabel: "Flat 20% off",
    },
    syringes: {
        id: 4,
        title: "Syringes",
        description: "Discover a wide range of genuine medicines and health products online.",
        getQuoteBtn: 'Get Quote',
        discountLabel: "Upto 25% off",
    }
}
export default bigOffer
