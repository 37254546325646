import React from 'react'
import profile from './../../assets/signInProfile.png'
import { Link } from 'react-router-dom'
import backButton from './../../assets/Backbutton2.png'

const InquiryDetails = () => {
    return (
        <section className="flex justify-center font-poppins bg-[#F3F4F4] h-screen">
            <div className='container px-6 py-12'>
                <div className='w-full'>
                    <div className='flex items-center gap-4 py-4'>
                        <button className='text-[#848484] '>
                            <Link to="/" className='flex items-center gap-2'>
                                <img src={backButton} alt="" /> Home
                            </Link>
                        </button>
                        <button className='text-[#848484] flex items-center gap-2'>
                            <Link to="/myProfile">
                                &gt; My Profile
                            </Link>
                        </button>
                        <button className='text-[#848484] flex items-center gap-2'>
                            <Link to="/myProfile">
                                &gt; Help
                            </Link>
                        </button>
                        <button className='text-[#848484] flex items-center gap-2'>
                            <Link to="/myProfile">
                                &gt; Inquiry History
                            </Link>
                        </button>
                        <button className='text-[#848484] flex items-center gap-2'>
                            &gt; Details
                        </button>
                    </div>
                    <div className='grid grid-cols-5 gap-4'>
                        <div className='col-span-5  p-4 bg-white'>
                            <div className='col-span-5 px-4'>

                            </div>
                            <div className='flex items-start px-4'>
                                <div className=' flex items-center justify-center h-full '>
                                    <img src={profile} alt="" className='w-full h-auto bg-cover object-cover' />
                                </div>
                                <div className='flex justify-between flex-col px-4 '>
                                    <div className='font-semibold text-sm md:text-base xl:text-lg'>Dr. Harshi</div>
                                    <div className='text-[#848484] text-xs md:text-sm xl:text-base'>You Sent on 12 Aug 2024 at 3:44 PM</div>
                                </div>
                                {/* <div className='w-[10%] relative h-full'>
                                    <img src={rightArrow} alt="" className='absolute top-[45%]' />
                                </div> */}
                            </div>
                            <div className='text-sm md:text-base xl:text-lg px-4'>Hi Medicabuyer team, I'm Dr. Harshi. I’d like to purchase multiple products at once. Do you offer any ...... Hi Medicabuyer team, I'm Dr. Harshi. I’d like to purchase multiple products at once. Do you offer any ...... Hi Medicabuyer team, I'm Dr. Harshi. I’d like to purchase multiple products at once. Do you offer any ......</div>
                        </div>
                        <div className='col-span-5  p-4 bg-white'>
                            <div className='col-span-5 px-4'>

                            </div>
                            <div className='flex items-start px-4'>
                                <div className=' flex items-center justify-center h-full '>
                                    <img src={profile} alt="" className='w-full h-auto bg-cover object-cover' />
                                </div>
                                <div className='flex justify-between flex-col px-4 '>
                                    <div className='font-semibold text-sm md:text-base xl:text-lg'>Dr. Harshi</div>
                                    <div className='text-[#848484] text-xs md:text-sm xl:text-base'>You Sent on 12 Aug 2024 at 3:44 PM</div>
                                </div>
                                {/* <div className='w-[10%] relative h-full'>
                                    <img src={rightArrow} alt="" className='absolute top-[45%]' />
                                </div> */}
                            </div>
                            <div className='text-sm md:text-base xl:text-lg px-4'>Hi Medicabuyer team, I'm Dr. Harshi. I’d like to purchase multiple products at once. Do you offer any ...... Hi Medicabuyer team, I'm Dr. Harshi. I’d like to purchase multiple products at once. Do you offer any ...... Hi Medicabuyer team, I'm Dr. Harshi. I’d like to purchase multiple products at once. Do you offer any ......</div>
                        </div>
                        <div className='col-span-5  p-4 bg-white'>
                            <div className='col-span-5 px-4'>

                            </div>
                            <div className='flex items-start px-4'>
                                <div className=' flex items-center justify-center h-full '>
                                    <img src={profile} alt="" className='w-full h-auto bg-cover object-cover' />
                                </div>
                                <div className='flex justify-between flex-col px-4 '>
                                    <div className='font-semibold text-sm md:text-base xl:text-lg'>Dr. Harshi</div>
                                    <div className='text-[#848484] text-xs md:text-sm xl:text-base'>You Sent on 12 Aug 2024 at 3:44 PM</div>
                                </div>
                                {/* <div className='w-[10%] relative h-full'>
                                    <img src={rightArrow} alt="" className='absolute top-[45%]' />
                                </div> */}
                            </div>
                            <div className='text-sm md:text-base xl:text-lg px-4'>Hi Medicabuyer team, I'm Dr. Harshi. I’d like to purchase multiple products at once. Do you offer any ...... Hi Medicabuyer team, I'm Dr. Harshi. I’d like to purchase multiple products at once. Do you offer any ...... Hi Medicabuyer team, I'm Dr. Harshi. I’d like to purchase multiple products at once. Do you offer any ......</div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default InquiryDetails
