// customerReviewsSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchAllCustomerReviews } from '../apiEndPoint';

export const fetchCustomerReviews = createAsyncThunk(
    'customerReviews/fetchAll',
    async (_, { rejectWithValue }) => {
        try {
            return await fetchAllCustomerReviews();
        } catch (error) {
            return rejectWithValue(error.response.data || 'Failed to fetch customer reviews');
        }
    }
);

const customerReviewsSlice = createSlice({
    name: 'customerReviews',
    initialState: {
        reviews: [],
        loading: false,
        error: null,
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchCustomerReviews.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchCustomerReviews.fulfilled, (state, action) => {
                state.loading = false;
                state.reviews = action.payload;
            })
            .addCase(fetchCustomerReviews.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            });
    },
});

export default customerReviewsSlice.reducer;
