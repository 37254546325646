const header = {
    searchCategory: "Search Category",
    goBtn: "Go",
    headerMenu: {
        signIn: "Sign In",
        signUp: "Sign Up",
        cart: "Cart",
        orders: "Orders",
        contactUs: 'Contact Us',
        sellWithUs: "Sell with Us",
        help: "Help",
        home: "Home",
    },
}
export default header;