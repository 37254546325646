import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'; // Import BrowserRouter
import Home from './pages/Home.jsx';
import Header from './components/Header.jsx';
import Footer from './components/Footer.jsx';
import Category from './components/Category.jsx';
import AboutUs from './pages/aboutUs/AboutUs.jsx';
import ScrollToTop from './functionality/ScrollToTop.jsx';
import SeeAllShopCategory from './components/shopByCategory/SeeAllShopCategory.jsx';
import SubCategory from './components/shopByCategory/SubCategory.jsx';
import ProductPage from './pages/productPage/ProductPage.jsx';
import Cart from './pages/cart/Cart.jsx';
import ReturnsAndReturn from './pages/policies/ReturnsAndReturn.jsx';
import policyString from './pages/policies/policyString.js';
import BestSellerPage from './pages/bestSellerPage/BestSellerPage.jsx';
import ContactUs from './pages/contactUs/ContactUs.jsx';
import ForgetPassword from './components/signIn/completeProfile/ForgetPassword.jsx';
import MyProfile from './pages/myProfile/MyProfile.jsx';
import OrderDetails from './components/orderDetails/OrderDetails.jsx';
import Breadcrumbs from './Breadcrumbs.js';
import InquiryDetails from './pages/myProfile/InquiryDetails.jsx';

function App() {

  return (
    <Router>
      {/* <Breadcrumbs /> */}
      <ScrollToTop>
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path='/about' element={<AboutUs />} />
          <Route path='/allShopCategory' element={<SeeAllShopCategory />} />
          <Route path='/subCategory' element={<SubCategory />} />
          <Route path='/mainProduct' element={<ProductPage />} />
          <Route path='/cart' element={<Cart />} />
          <Route path='/returnAndRefundPolicy' element={<ReturnsAndReturn policyArray={policyString.returnAndRefundString.policy} policyTitle={policyString.returnAndRefundString.title} />} />
          <Route path='/privacyPolicy' element={<ReturnsAndReturn policyArray={policyString.privacyPolicy.policy} policyTitle={policyString.privacyPolicy.title} />} />
          <Route path='/termsOfUSe' element={<ReturnsAndReturn policyArray={policyString.termsOfUse.policy} policyTitle={policyString.termsOfUse.title} />} />
          <Route path='/shoppingPolicy' element={<ReturnsAndReturn policyArray={policyString.shoppingPolicy.policy} policyTitle={policyString.shoppingPolicy.title} />} />
          <Route path="/bestSellerPage" element={<BestSellerPage />} />
          <Route path="/contactUs" element={<ContactUs />} />
          <Route path="/fp" element={<ForgetPassword />} />
          <Route path='/myProfile' element={<MyProfile />} />
          <Route path='/orderDetails' element={<OrderDetails />} />
          <Route path='/inquiryDetails' element={<InquiryDetails />} />
        </Routes>
        <Footer />
      </ScrollToTop>
    </Router>
  );
}

export default App;
