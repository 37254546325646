const refurbishedProducts = {
    title: "Refurbished Products",
    desc: "Discover a wide range of genuine medicines and health products online.",
    seeAll: "See all",
    xRayMachine: {
        id: 1,
        title: "X-ray Machine",
        description: "Discover a wide range of genuine medicines and health products online.",
        getQuoteBtn: 'Get Quote',
    },
    medicalEquipment: {
        id: 2,
        title: "Medical Equipment",
        description: "Discover a wide range of genuine medicines and health products online.",
        getQuoteBtn: 'Get Quote',
    },
    machine: {
        id: 3,
        title: 'Machine',
        description: "Discover a wide range of genuine medicines and health products online.",
        getQuoteBtn: 'Get Quote',
    },
    product1: {
        id: 4,
        title: "Product1",
        description: "Discover a wide range of genuine medicines and health products online.",
        getQuoteBtn: 'Get Quote',
    }
}
export default refurbishedProducts