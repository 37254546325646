import React, { useEffect } from "react";
import consumables from "./consumablesString";
import CommonBigOffer from "../bigOffer/CommonBigOffer";
import { GrLinkNext, GrLinkPrevious } from "react-icons/gr";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchSubCategoriesAsync } from "../../reduxStore/subCategorySlice";
import { fetchProductsBySubCategoryAsyncforConsumables } from "../../reduxStore/productSlice";
import shopByCategory from "../shopByCategory/shopByCategoryString";

// Custom Arrow Components
const PrevArrow = ({ onClick }) => (
  <button onClick={onClick} className="arrow-styling">
    <GrLinkPrevious />
  </button>
);

const NextArrow = ({ onClick }) => (
  <button onClick={onClick} className="arrow-styling">
    <GrLinkNext />
  </button>
);

const Consumables = ({ like }) => {
  const dispatch = useDispatch();
  const { items: subCategories } = useSelector((state) => state.subCategories);
  const { filteredItemsForConsumables, loadingFilteredItemsForConsumables } =
    useSelector((state) => state.products);

  // Filter consumables subcategory
  const consumablesSubCategory = subCategories.find(
    (subCategory) => subCategory.category.name === "Consumables"
  );

  useEffect(() => {
    // Fetch subcategories on mount
    dispatch(fetchSubCategoriesAsync());
  }, [dispatch]);

  useEffect(() => {
    // Fetch products if consumables subcategory exists
    if (consumablesSubCategory) {
      dispatch(
        fetchProductsBySubCategoryAsyncforConsumables(
          consumablesSubCategory._id
        )
      );
    }
  }, [dispatch, consumablesSubCategory]);

  // Slick slider settings
  const settings = {
    dots: false,
    infinite: true,
    speed: 300,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      { breakpoint: 1536, settings: { slidesToShow: 4 } },
      { breakpoint: 1280, settings: { slidesToShow: 4 } },
      { breakpoint: 1024, settings: { slidesToShow: 3 } },
      { breakpoint: 768, settings: { slidesToShow: 2 } },
      { breakpoint: 640, settings: { slidesToShow: 1 } },
    ],
  };

  return (
    <section className="flex justify-center font-poppins">
      <div className="container px-6 py-12">
        <div className="w-full flex justify-between items-center">
          <div className="w-full">
            <div className="flex items-center justify-between">
              <h2 className="font-semibold text-2xl xl:text-3xl">
                {consumables.title}
              </h2>
              <button className="underline text-[#1A444C] text-xs md:text-base lg:text-lg xl:text-xl">
                <Link to="/allShopCategory">{consumables.seeAll}</Link>
              </button>
            </div>
            <div className="text-[#848484] text-xs md:text-sm lg:text-base">
              {consumables.desc}
            </div>
          </div>
        </div>

        {/* Slick Slider Section */}
        <div className="py-4">
          {loadingFilteredItemsForConsumables ? (
            <p>Loading consumable products...</p>
          ) : filteredItemsForConsumables.length > 0 ? (
            <Slider {...settings}>
              {filteredItemsForConsumables.map((product) => (
                <CommonBigOffer
                  key={product._id}
                  img={`${process.env.REACT_APP_BASE_URL}/${product.image}`}
                  name={product.name}
                  price={product.variation[0].price}
                  off={`${product.variation[0].discount}% off`}
                  data={shopByCategory.medicalDeviceArray.thermoMeter}
                  dataOrg={product}
                />
              ))}
            </Slider>
          ) : (
            <p>No consumable products found.</p>
          )}
        </div>
      </div>
    </section>
  );
};

export default Consumables;
