import React from 'react';
import heroSlider from '../stringFolder/heroSlider';
import Slider from 'react-slick';

const HeroSlider = () => {
    // Slick slider settings
    const settings = {
        dots: true, // Show dots below the slider
        infinite: true, // Enable infinite loop
        speed: 500, // Animation speed
        slidesToShow: 1, // Number of slides to show at once
        slidesToScroll: 1, // Number of slides to scroll at once
        autoplay: true, // Enable autoplay
        autoplaySpeed: 3000, // Autoplay interval in ms
        appendDots: dots => (
            <div style={{ position: 'absolute', bottom: '20px', left: '50%', transform: 'translateX(-50%)' }}>
                <ul style={{ display: 'flex', justifyContent: 'center', margin: 0, padding: 0 }}>
                    {dots}
                </ul>
            </div>
        ),
    };
    // Create an array of slides (you can customize this if you have more data)
    const slides = new Array(3).fill(0); // Change 3 to the number of times you want to repeat

    return (
        <section className="flex justify-center bg-gradient-to-r from-[#2F94A6] to-[#5AB5D2] font-poppins">
            {/* Slick Slider Section */}
            <div className="w-full ">
                <Slider {...settings} className='flex justify-center'>
                    {slides.map((_, index) => (
                        // make this content in the center horizontally 
                        <div key={index} className="heroBackground ">
                            <div className='container py-8 lg:pl-[13%] h-full flex items-center justify-between'>
                                <div className=' w-full'>
                                    <div className='lg:w-[60%] pl-12 flex flex-col gap-4 lg:gap-8'>
                                        <h1 className='text-white font-bold text-xl sm:text-2xl md:text-3xl lg:text-4xl xl:text-5xl 2xl:text-6xl uppercase'>{heroSlider.off}</h1>
                                        <h2 className='text-white font-semibold text-lg md:text-xl lg:text-2xl xl:text-3xl 2xl:text-4xl'>{heroSlider.product}</h2>
                                        <div className='text-white text-sm sm:text-base md:text-lg lg:text-xl'>{heroSlider.desc}</div>
                                        <div className='text-white'>
                                            <button className='rounded-full bg-white text-black py-2 xl:py-3 px-4 xl:px-6 font-semibold text-sm sm:text-base md:text-lg lg:text-xl'>{heroSlider.explore}</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </Slider>
            </div>
        </section>
    );
}

export default HeroSlider;
