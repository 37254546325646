// accountSlice.js
import { createSlice } from '@reduxjs/toolkit';

const accountSlice = createSlice({
    name: 'account',
    initialState: {
        email: '',
        password: '',
        mobile: '',
        notify: false,
    },
    reducers: {
        setEmail(state, action) {
            state.email = action.payload;
        },
        setPassword(state, action) {
            state.password = action.payload;
        },
        setMobile(state, action) {
            state.mobile = action.payload;
        },
        toggleNotify(state) {
            state.notify = !state.notify;
        },
        resetForm(state) {
            state.email = '';
            state.password = '';
            state.mobile = '';
            state.notify = false;
        },
    },
});

export const { setEmail, setPassword, setMobile, toggleNotify, resetForm } = accountSlice.actions;

export default accountSlice.reducer;
