import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import backButton from './../../assets/Backbutton2.png'
import rightArrow from './../../assets/rightArrow.png'

const OrderDetails = () => {
    const location = useLocation();
    const orderData = location.state;
    return (
        <section className="flex justify-center font-poppins bg-[#F3F4F4] h-screen">
            <div className='container px-6 py-12'>
                <div className='w-full'>
                    <div className='flex items-center gap-4 py-4'>
                        <button className='text-[#848484] '>
                            <Link to="/" className='flex items-center gap-2'>
                                <img src={backButton} alt="" /> Home
                            </Link>
                        </button>
                        <button className='text-[#848484] flex items-center gap-2'>
                            <Link to="/myProfile">
                                &gt; My Profile
                            </Link>
                        </button>
                        <button className='text-[#848484] flex items-center gap-2'>
                            <Link to="/myProfile">
                                &gt; My Orders
                            </Link>
                        </button>
                        <button className='text-[#848484] flex items-center gap-2'>
                            &gt; Order Details
                        </button>
                    </div>
                    <div className='grid grid-cols-5 gap-4'>
                        <div className='col-span-4 w-[95%]  p-4 bg-white'>
                            <div className='col-span-5 px-4'>

                            </div>
                            <div className='flex items-start px-4 h-24'>
                                <div className='w-[10%] h-full'>
                                    <img src={orderData.imgSrc} alt="" className='w-full h-full bg-cover object-cover' />
                                </div>
                                <div className='w-[80%] px-4 py-2'>
                                    <div className='font-semibold text-base md:text-lg xl:text-xl'>{orderData.title}</div>
                                    <div className='text-[#848484] text-sm md:text-base xl:text-lg'>{orderData.description}</div>
                                </div>
                                {/* <div className='w-[10%] relative h-full'>
                                    <img src={rightArrow} alt="" className='absolute top-[45%]' />
                                </div> */}
                            </div>
                        </div>
                        <div className='col-span-1 bg-white  p-4'>
                            <div className='font-semibold'>Delivery Address</div>
                            <div className='font-semibold'>Harshi</div>
                            <div className='text-[#848484]'>A-22, ABC Building, Y2 Circle, Shiv temple road, Gujarat, India</div>
                            <div><span className='font-semibold'>Mobile No.:</span> 965745454</div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default OrderDetails
