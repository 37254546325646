import axios from "axios";

const axiosApi = axios.create({
    baseURL: `${process.env.REACT_APP_BASE_URL}/api/`,
});

export const axiosInstance = axiosApi;

axiosInstance.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem("SignIn token");
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

axiosInstance.interceptors.response.use(
    (response) => {
        // console.log("Response:", response);
        return response;
    },
    (error) => {
        console.log("Error response:", error.response);
        if (error.response && error.response.status === 401) {
            console.log("Unauthorized, redirecting to login...");
            localStorage.clear();
            // localStorage.clear();
            // window.location.href = "/login";
            // window.location.reload();
        }
        return Promise.reject(error);
    }
);

export const registerUser = (formData) => async (dispatch) => {
    try {
        const response = await axiosInstance.post(`users/register`, formData);

        // Dispatch actions based on the response
        dispatch({ type: "REGISTER_SUCCESS", payload: response.data });

        return response.data;
    } catch (error) {
        console.error("Registration failed:", error);
        dispatch({ type: "REGISTER_FAILURE", payload: error.message });
        throw error;
    }
};

// Function to send OTP to the user's email
export const sendOtp = (email) => async (dispatch) => {
    try {
        const response = await axiosInstance.post(`users/sendotp`, { email });

        // Dispatch success action if needed
        dispatch({ type: "SEND_OTP_SUCCESS", payload: response.data });

        return response.data;
    } catch (error) {
        console.error("Failed to send OTP:", error);

        // Dispatch failure action if needed
        dispatch({ type: "SEND_OTP_FAILURE", payload: error.message });

        throw error;
    }
};
export const verifyOtp = (otpData) => async (dispatch) => {
    try {
        const response = await axiosInstance.post(`users/verifyotp`, otpData);

        // Dispatch success action if needed
        dispatch({ type: "VERIFY_OTP_SUCCESS", payload: response.data });

        return response.data;
    } catch (error) {
        console.error("Failed to verify OTP:", error);

        // Dispatch failure action if needed
        dispatch({ type: "VERIFY_OTP_FAILURE", payload: error.message });

        throw error;
    }
};

// Action creator for logging in the user
export const loginUser = (email, password) => async (dispatch) => {
    try {
        const response = await axiosInstance.post("users/login", {
            email,
            password,
        });

        // Dispatch an action for successful login
        dispatch({ type: "LOGIN_SUCCESS", payload: response.data });

        return response.data;
    } catch (error) {
        // Dispatch an error action if login fails
        dispatch({ type: "LOGIN_ERROR", payload: error.message });

        console.error("Login failed:", error);
        throw error;
    }
};

export const fetchUserProfile = async (id) => {
    try {
        const response = await axiosInstance.get(`users/profile`, id);
        console.log(response.data);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const fetchCategories = async () => {
    try {
        const response = await axiosInstance.get(`categories/all`);
        return response.data.data; // Return only response data, not the entire response
    } catch (error) {
        throw error;
    }
};

export const fetchSubCategories = async () => {
    try {
        const response = await axiosInstance.get(`subcategories/all`);
        console.log(response.data.data);
        return response.data.data;
    } catch (error) {
        throw error;
    }
};
export const fetchProducts = async () => {
    try {
        const response = await axiosInstance.get(`products/all`);
        console.log(response.data.data);
        return response.data.data;
    } catch (error) {
        throw error;
    }
};
export const fetchProductBySubCategory = async (id) => {
    try {
        const response = await axiosInstance.get(`products/subcategory/${id}`);
        return response.data.data;
    } catch (error) {
        console.error("Failed to fetch product by subcategory", error);
        throw error;
    }
};
export const fetchAllCustomerReviews = async () => {
    try {
        const response = await axiosInstance.get(`customer-reviews`);
        console.log(response.data.data);
        return response.data.data;
    } catch (error) {
        throw error;
    }
};
export const fetchAwardsAndRecognition = async () => {
    try {
        const response = await axiosInstance.get(`awards`);
        console.log(response.data.data);
        return response.data.data;
    } catch (error) {
        throw error;
    }
};
export const contactUsForm = async (contactData) => {
    try {
        const response = await axiosInstance.post("/contactUs", contactData);
        return response.data;
    } catch (error) {
        if (error.response) {
            // The request was made and the server responded with a status code outside of 2xx
            console.error("Error response:", error.response);
            console.error("Error status:", error.response.status);
            console.error("Error data:", error.response.data);
        } else if (error.request) {
            // The request was made but no response was received
            console.error("No response received:", error.request);
        } else {
            // Something went wrong in setting up the request
            console.error("Request setup error:", error.message);
        }
        throw error; // Rethrow the error so it can be handled in the calling component
    }
};
// search API
export const searchAll = async (text) => {
    try {
        const response = await axiosInstance.get(`search?q=${text}`);
        return response.data.data;
    } catch (error) {
        console.error("Failed to fetch product by subcategory", error);
        throw error;
    }
};
// big offers
export const fetchBigOffer = async () => {
    try {
        const response = await axiosInstance.get(`products/filter/bigoffer`);
        console.log(response.data);
        return response.data.data;
    } catch (error) {
        throw error;
    }
};
// refurbished products
export const fetchRefurbishedProducts = async () => {
    try {
        const response = await axiosInstance.get(`products/filter/refurbished`);
        console.log(response.data);
        return response.data.data;
    } catch (error) {
        throw error;
    }
}
// cart items
export const fetchCartItems = async () => {
    try {
        const response = await axiosInstance.get(`cart`);
        console.log(response.data);
        return response.data.data;
    } catch (error) {
        throw error;
    }
}
//Add to cart
export const addToCart = async ({ id, qnt }) => {
    try {
        const response = await axiosInstance.post('cart', { id, qnt });
        console.log(response.data);
        return response.data.data;
    } catch (error) {
        throw error;
    }
};
//Delete from cart
export const deleteFromCart = async (id) => {
    try {
        const response = await axiosInstance.delete(`cart/${id}`);
        return response.data.data;
    } catch (error) {
        console.error("Failed to delete product from cart", error);
        throw error;
    }
};