import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchProducts as fetchProductsApi, fetchProductBySubCategory } from './../apiEndPoint';

// Async thunk to fetch products
export const fetchProductsAsync = createAsyncThunk(
    'products/fetchProducts',
    async () => {
        const response = await fetchProductsApi();
        return response;
    }
);

// Async thunk to fetch filtered products by subcategory
export const fetchProductsBySubCategoryAsync = createAsyncThunk(
    'products/fetchProductsBySubCategory',
    async (subCategoryId) => {
        const response = await fetchProductBySubCategory(subCategoryId);
        return response;
    }
);

// Async thunk specifically for fetching products for Consumables component
export const fetchProductsBySubCategoryAsyncforConsumables = createAsyncThunk(
    'products/fetchProductsBySubCategoryForConsumables',
    async (subCategoryId) => {
        const response = await fetchProductBySubCategory(subCategoryId);
        return response;
    }
);

const productSlice = createSlice({
    name: 'products',
    initialState: {
        items: [],
        filteredItems: [],                     // For general filtered products
        filteredItemsForConsumables: [],       // For consumables-specific filtered products
        loadingItems: false,                   // Loading state for fetchProductsAsync
        loadingFilteredItems: false,           // Loading state for fetchProductsBySubCategoryAsync
        loadingFilteredItemsForConsumables: false, // Loading state for fetchProductsBySubCategoryAsyncforConsumables
        error: null,
    },
    reducers: {
        setFilteredProductsList: (state, action) => {
            state.filteredItems = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            // fetchProductsAsync cases
            .addCase(fetchProductsAsync.pending, (state) => {
                state.loadingItems = true;
                state.error = null;
            })
            .addCase(fetchProductsAsync.fulfilled, (state, action) => {
                state.loadingItems = false;
                state.items = action.payload;
            })
            .addCase(fetchProductsAsync.rejected, (state, action) => {
                state.loadingItems = false;
                state.error = action.error.message;
            })

            // fetchProductsBySubCategoryAsync cases
            .addCase(fetchProductsBySubCategoryAsync.pending, (state) => {
                state.loadingFilteredItems = true;
                state.error = null;
            })
            .addCase(fetchProductsBySubCategoryAsync.fulfilled, (state, action) => {
                state.loadingFilteredItems = false;
                state.filteredItems = action.payload;
            })
            .addCase(fetchProductsBySubCategoryAsync.rejected, (state, action) => {
                state.loadingFilteredItems = false;
                state.error = action.error.message;
            })

            // fetchProductsBySubCategoryAsyncforConsumables cases
            .addCase(fetchProductsBySubCategoryAsyncforConsumables.pending, (state) => {
                state.loadingFilteredItemsForConsumables = true;
                state.error = null;
            })
            .addCase(fetchProductsBySubCategoryAsyncforConsumables.fulfilled, (state, action) => {
                state.loadingFilteredItemsForConsumables = false;
                state.filteredItemsForConsumables = action.payload;
            })
            .addCase(fetchProductsBySubCategoryAsyncforConsumables.rejected, (state, action) => {
                state.loadingFilteredItemsForConsumables = false;
                state.error = action.error.message;
            });
    },
});

export const { setFilteredProductsList } = productSlice.actions;
export default productSlice.reducer;
