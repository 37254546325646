import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setInitialTitle } from '../../reduxStore/subCategorySlice';

const CommonShopCategory = ({ img, name }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const initialTitle = useSelector((state) => state.subCategories.initialTitle);

    const [isSliding, setIsSliding] = useState(false);
    const [startX, setStartX] = useState(0);

    const handleMouseDown = (e) => {
        setStartX(e.clientX); // Record the initial mouse position
        setIsSliding(false); // Reset sliding flag
    };

    const handleMouseMove = (e) => {
        if (Math.abs(e.clientX - startX) > 10) { // Threshold for sliding
            setIsSliding(true);
        }
    };

    const handleMouseUp = () => {
        if (!isSliding) {
            handleSubCategory();
        }
    };

    const handleSubCategory = () => {
        navigate("/subCategory", { state: { categoryName: name } });
        console.log(name);
        dispatch(setInitialTitle(name));
    };

    return (
        <button
            onMouseDown={handleMouseDown}
            onMouseMove={handleMouseMove}
            onMouseUp={handleMouseUp}
            className='flex flex-col items-center w-full'
        >
            <div className=' w-full h-auto  mx-2'>
                <img src={img} alt="" className='rounded-full w-auto sm:w-[80%] md:w-[97%] h-40 sm:h-60 mx-auto' crossOrigin="anonymous" />
            </div>
            <div className='text-center py-2 text-base md:text-lg xl:text-lg '>{name}</div>
        </button>
    );
};

export default CommonShopCategory;
