import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

export const fetchCountries = createAsyncThunk('profile/fetchCountries', async () => {
    const response = await axios.get('/api/countries'); // Replace with actual API endpoint
    return response.data;
});

export const fetchStates = createAsyncThunk('profile/fetchStates', async (countryId) => {
    const response = await axios.get(`/api/states?country=${countryId}`); // Replace with actual API endpoint
    return response.data;
});

export const fetchCities = createAsyncThunk('profile/fetchCities', async (stateId) => {
    const response = await axios.get(`/api/cities?state=${stateId}`); // Replace with actual API endpoint
    return response.data;
});

const profileSlice = createSlice({
    name: 'profile',
    initialState: {
        firstName: '',
        lastName: '',
        email: '',
        mobile: '',
        whatsapp: '',
        address: '',
        country: '',
        state: '',
        city: '',
        countries: [],
        states: [],
        cities: [],
        errors: {},
    },
    reducers: {
        setField: (state, action) => {
            state[action.payload.field] = action.payload.value;
        },
        setErrors: (state, action) => {
            state.errors = action.payload;
        },
        clearErrors: (state) => {
            state.errors = {};
        },
        resetProfileForm: (state) => {
            return {
                ...state,
                firstName: '',
                lastName: '',
                email: '',
                mobile: '',
                whatsapp: '',
                address: '',
                country: '',
                state: '',
                city: '',
                errors: {},
            };
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchCountries.fulfilled, (state, action) => {
                state.countries = action.payload;
            })
            .addCase(fetchStates.fulfilled, (state, action) => {
                state.states = action.payload;
            })
            .addCase(fetchCities.fulfilled, (state, action) => {
                state.cities = action.payload;
            });
    }
});

export const { setField, setErrors, clearErrors, resetProfileForm } = profileSlice.actions;
export default profileSlice.reducer;
