import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { showFormOpen } from '../../reduxStore/modalSlice';
import CompleteProfile from './completeProfile/CompleteProfile';
import edit from './../../assets/edit.png';
import { sendOtp, verifyOtp } from '../../apiEndPoint';
import Swal from 'sweetalert2';

const otpCss = {
    width: "40px",
    height: "40px",
    margin: "5px",
    textAlign: "center",
    fontSize: "1.2em",
};

const VerifyOTP = ({ length, onsubmit, mobile, email, Ref, onclose }) => {
    const formOpen = useSelector((state) => state.modal.formOpen);
    const dispatch = useDispatch();
    const inputRef = useRef([]);
    const nonSignInRef = useRef();
    const [value, setValue] = useState(new Array(length).fill(""));

    useEffect(() => {
        const handleClickOutside = (e) => {
            if (Ref.current && !Ref.current.contains(e.target)) {
                onclose();
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [Ref, onclose]);

    const onChangeHandler = ({ target: { value: inputValue } }, index) => {
        if (isNaN(inputValue)) return;

        const newValue = [...value];
        newValue[index] = inputValue.slice(-1);
        setValue(newValue);

        if (inputValue && index < length - 1) inputRef.current[index + 1].focus();
    };

    const onClickHandler = (index) => inputRef.current[index].setSelectionRange(1, 1);

    const onKeyDownHandler = (e, index) => {
        if (e.key === "Backspace" && index > 0 && !value[index]) {
            inputRef.current[index - 1].focus();
        }
    };

    const handleResendOTP = async (e) => {
        e.preventDefault();
        await dispatch(sendOtp(email));

        // Clear the OTP input fields
        setValue(new Array(length).fill(""));

        // Focus on the first input field after clearing
        if (inputRef.current[0]) inputRef.current[0].focus();
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const finalValue = value.join(""); // OTP entered by the user

        if (finalValue.length === length) {
            console.log("OTP submitted:", finalValue); // Logs the entered OTP

            const otpData = email ? { email, otp: finalValue } : { mobile, otp: finalValue };

            try {
                const response = await dispatch(verifyOtp(otpData)); // Call the API and pass the data
                console.log(response); // Log response to inspect its structure

                if (response && response.message === "OTP verified") {
                    // Display success alert
                    Swal.fire({
                        icon: 'success',
                        title: 'Success',
                        text: 'Your OTP has been verified successfully!',
                    }).then((result) => {
                        if (result.isConfirmed) {
                            // Call dispatch only after clicking OK
                            dispatch(showFormOpen());
                        }
                    });
                }
            } catch (error) {
                console.error("Error during OTP verification:", error);

                // Display error alert
                Swal.fire({
                    icon: 'error',
                    title: 'Verification Failed',
                    text: error.response?.data?.message || 'Failed to verify OTP. Please try again.',
                });
            }
        } else {
            Swal.fire({
                icon: 'warning',
                title: 'Incomplete OTP',
                text: 'Please enter a complete OTP.',
            });
        }
    };



    useEffect(() => {
        if (inputRef.current[0]) inputRef.current[0].focus();
    }, []);

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-sm flex flex-col justify-center items-center z-50">
            <div ref={formOpen ? Ref : nonSignInRef} className="bg-white rounded-3xl shadow-lg w-[50%] h-auto max-h-[90%] overflow-y-auto relative">
                <div className='text-center p-6'>
                    <h3 className='font-semibold text-lg md:text-xl lg:text-2xl xl:text-3xl 2xl:text-4xl'>OTP Verification</h3>
                    <div className='flex gap-2'>
                        {mobile ?
                            <div className='text-[#848484] text-sm lg:text-base xl:text-lg'>
                                Please enter the OTP (one-time password) we sent to your mobile no. {mobile}
                            </div>
                            :
                            <div className='text-[#848484] text-sm lg:text-base xl:text-lg'>
                                Please enter the OTP (one-time password) we sent to your email id. {email}
                            </div>
                        }
                        {/* {email &&
                        } */}
                        <span className='flex items-center gap-1'>
                            <img src={edit} alt="" />
                            Edit
                        </span>
                    </div>
                    <form onSubmit={handleSubmit}>
                        {value.map((item, index) => (
                            <input
                                key={index}
                                ref={(input) => (inputRef.current[index] = input)}
                                value={item}
                                style={otpCss}
                                placeholder="0"
                                onChange={(e) => onChangeHandler(e, index)}
                                onClick={() => onClickHandler(index)}
                                onKeyDown={(e) => onKeyDownHandler(e, index)}
                            />
                        ))}
                        <div className='flex justify-between items-center w-[50%] mx-auto'>
                            <div>
                                Didn’t receive OTP? <button onClick={handleResendOTP} className='font-semibold underline'>Resend</button>
                            </div>
                            <div>
                                time
                            </div>
                        </div>
                        <div className='grid grid-cols-2 gap-4 text-base lg:text-lg mt-4'>
                            <button type="submit" className={`col-span-2 bg-[#1A444C] rounded-full p-4 text-white`}>
                                Submit & Next
                            </button>
                        </div>
                    </form>
                </div>
            </div>
            {formOpen && (
                <CompleteProfile refs={Ref} Emailid={email} mobileNo={mobile} />
            )}
        </div>
    );
};

export default VerifyOTP;
