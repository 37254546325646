const consumables = {
    title: "Consumables",
    desc: 'Discover a wide range of genuine medicines and health products online.',
    seeAll: "See all",
    injections: {
        title: "Injections",
        description: "Discover a wide range of genuine medicines and health products online.",
        price: "₹5599",
        crossPrice: '₹6599',
        off: "10% off",
        buyNow: "Buy Now",
        addToCart: "Add to Cart",
    },
    bandages: {
        title: "Bandages",
        description: "Discover a wide range of genuine medicines and health products online.",
        price: '₹5599',
        crossPrice: "₹6599",
        off: '10% off',
        buyNow: "Buy Now",
        addToCart: "Add to Cart",
    },
    surgicalGloves: {
        title: 'Surgical gloves',
        description: "Discover a wide range of genuine medicines and health products online.",
        price: '₹5599',
        crossPrice: "₹6599",
        off: '10% off',
        buyNow: "Buy Now",
        addToCart: "Add to Cart",
    },
    syringes: {
        title: "Syringes",
        description: "Discover a wide range of genuine medicines and health products online.",
        price: '₹5599',
        crossPrice: "₹6599",
        off: '10% off',
        buyNow: "Buy Now",
        addToCart: "Add to Cart",
    }
}
export default consumables