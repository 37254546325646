import React from 'react'
import recognition from './../../assets/recognition.png'

const CommonAward = ({ title, description }) => {
    return (
        <div className='border border-[#1A444C]/25 rounded-3xl h-full p-4 lg:p-6'>
            <div className='flex items-center gap-1'>
                <img src={recognition} alt="" />
                <div className='font-semibold'>{title}</div>
            </div>
            <div className='text-[#1A444C]  text-xs md:text-sm lg:text-base py-2'>{description}</div>
        </div >
    )
}

export default CommonAward
