import React from 'react'
import shopByCategory from './shopByCategoryString'
import medicalDevice from './../../assets/medicalDevice.png';
import medicalDressing from './../../assets/medicalDressigns.png';
import consumables from './../../assets/consumables.png';
import imagingSolution from './../../assets/imagingSolutions.png';
import medicalEquipments from './../../assets/medicalEquipmentsRound.png';
import dialysisCare from './../../assets/dialysisCare.png';
import CommonShopCategory from './CommonShopCategory';
import CustomerSays from '../customerSays/CustomerSays';
import AwardsAndRecognition from '../awardsRecognition/AwardsAndRecognition';
import Category from '../Category';
import { useSelector } from 'react-redux';

const SeeAllShopCategory = () => {
    const categories = useSelector((state) => state.categories.items);

    return (
        <>
            <Category />
            <section className="flex flex-col items-center justify-center font-poppins">
                <div className="container px-6 py-12 relative">
                    {/* Title and Button Section */}
                    <div className="w-full flex justify-between items-center">
                        <div className='text-center w-full'>
                            <h2 className="font-semibold text-2xl xl:text-3xl">{shopByCategory.title}</h2>
                            <div className="text-[#848484] text-xs md:text-sm lg:text-base">{shopByCategory.desc}</div>
                        </div>
                    </div>
                    <div className="py-12 grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6 gap-4 lg:gap-8">
                        {categories && categories.map((item) => (
                            <CommonShopCategory key={item._id} img={medicalDevice} name={item.name} />
                        ))}
                        {/* <CommonShopCategory img={medicalDevice} name={shopByCategory.medicalDevice} />
                        <CommonShopCategory img={medicalDressing} name={shopByCategory.medicalDressing} />
                        <CommonShopCategory img={consumables} name={shopByCategory.consumables} />
                        <CommonShopCategory img={imagingSolution} name={shopByCategory.imagingSolutions} />
                        <CommonShopCategory img={medicalEquipments} name={shopByCategory.medicalEquipments} />
                        <CommonShopCategory img={dialysisCare} name={shopByCategory.dialysisCare} />
                        <CommonShopCategory img={medicalDevice} name={shopByCategory.medicalDevice} />
                        <CommonShopCategory img={medicalDressing} name={shopByCategory.medicalDressing} />
                        <CommonShopCategory img={consumables} name={shopByCategory.consumables} />
                        <CommonShopCategory img={imagingSolution} name={shopByCategory.imagingSolutions} />
                        <CommonShopCategory img={medicalEquipments} name={shopByCategory.medicalEquipments} />
                        <CommonShopCategory img={dialysisCare} name={shopByCategory.dialysisCare} />
                        <CommonShopCategory img={medicalDevice} name={shopByCategory.medicalDevice} />
                        <CommonShopCategory img={medicalDressing} name={shopByCategory.medicalDressing} />
                        <CommonShopCategory img={consumables} name={shopByCategory.consumables} />
                        <CommonShopCategory img={imagingSolution} name={shopByCategory.imagingSolutions} />
                        <CommonShopCategory img={medicalEquipments} name={shopByCategory.medicalEquipments} />
                        <CommonShopCategory img={dialysisCare} name={shopByCategory.dialysisCare} />
                        <CommonShopCategory img={medicalDevice} name={shopByCategory.medicalDevice} />
                        <CommonShopCategory img={medicalDressing} name={shopByCategory.medicalDressing} />
                        <CommonShopCategory img={consumables} name={shopByCategory.consumables} />
                        <CommonShopCategory img={imagingSolution} name={shopByCategory.imagingSolutions} />
                        <CommonShopCategory img={medicalEquipments} name={shopByCategory.medicalEquipments} />
                        <CommonShopCategory img={dialysisCare} name={shopByCategory.dialysisCare} /> */}
                    </div>
                </div>
                <div className='w-full'>
                    <CustomerSays />
                </div>
                <div className='w-full'>
                    <AwardsAndRecognition />
                </div>
            </section>
        </>
    )
}

export default SeeAllShopCategory
