import React, { useEffect, useState } from 'react';
import myProfileString from './myProfileString';
import { ReactComponent as EditIcon } from './../../assets/Edit.svg'
import { ReactComponent as PersonalDetailIcon } from './../../assets/Personal Details selected.svg'
import rightArrow from './../../assets/rightArrow.png'
import littmann from './../../assets/xRayMachine.png'
import injections from './../../assets/injections.png'
import { useDispatch, useSelector } from 'react-redux';
import { setSignOutStatus } from '../../reduxStore/modalSlice';
import { useNavigate } from 'react-router-dom';
import CommonBigOffer from '../../components/bigOffer/CommonBigOffer';
import like from './../../assets/like (2).png'
import { removeFromWishlist, setError, setLoading, setWishlist } from '../../reduxStore/wishlistSlice';
import { fetchUserProfile } from '../../apiEndPoint';
import CreateNewInquiry from './CreateNewInquiry';
import Notification from '../contactUs/Notification';

const MyProfile = () => {
    const [selectedButton, setSelectedButton] = useState("Personal Details"); // State to store clicked button
    const [order, setOrder] = useState("All");
    const [help, setHelp] = useState("Order Inquiry History");
    const [expandedQuestion, setExpandedQuestion] = useState(null); // State to track which question is expanded
    const dispatch = useDispatch();
    const { items, loading, error } = useSelector(state => state.wishlist); // Access wishlist state
    const signInUser = useSelector((state) => state.modal.signInStatus);

    // useEffect(() => {
    //     // Placeholder for fetching wishlist from an API in the future
    //     const fetchWishlist = async () => {
    //         dispatch(setLoading(true));
    //         try {
    //             // Replace this with your API call
    //             const response = await new Promise((resolve) => {
    //                 setTimeout(() => resolve([
    //                     { id: 1, title: 'Product 1', price: '$100' },
    //                     { id: 2, title: 'Product 2', price: '$150' },
    //                 ]), 1000);
    //             });
    //             dispatch(setWishlist(response)); // Set wishlist data
    //         } catch (error) {
    //             dispatch(setError('Failed to fetch wishlist'));
    //         } finally {
    //             dispatch(setLoading(false));
    //         }
    //     };

    //     fetchWishlist();
    // }, [dispatch]);

    // useEffect(() => {
    //     const fetchProfile = async () => {
    //         try {
    //             const id = (localStorage.getItem('SignIn id'));
    //             const response = await dispatch(fetchUserProfile(id));
    //             console.log(response);
    //         } catch (error) {
    //             console.error("Error fetching profile:", error);
    //         }
    //     };
    //     fetchProfile();
    // }, [dispatch]);


    const handleRemove = (id) => {
        dispatch(removeFromWishlist({ id }));
    };
    const [confirmLogOut, setConfirmLogOut] = useState(false);
    const navigate = useNavigate();
    const handleButtonClick = (item) => {
        if (item === "Log Out") {
            // Log out functionality
            setConfirmLogOut(true);
        } else {
            // Handle other buttons
            setSelectedButton(item);
        }
    };
    const handleLogOut = () => {
        localStorage.clear();

        navigate("/");
        dispatch(setSignOutStatus());
    }
    const handleOrder = (item) => {
        setHelp(item);
        setOrder(item); // Update state with the clicked button value
    };
    const toggleAnswer = (index) => {
        if (expandedQuestion === index) {
            setExpandedQuestion(null); // If the clicked question is already expanded, collapse it
        } else {
            setExpandedQuestion(index); // Expand the clicked question
        }
    };
    const handleOrderDetails = (item) => {
        navigate(`/orderDetails`, { state: item });
    }
    const handleInquiryDetails = () => {
        navigate('/inquiryDetails');
    }
    const itemsOrder = [
        {
            title: "Ready to Ship (Ordered on Aug 12)",
            description: "3M Littmann Classic III Monitoring Stethoscope (5803) - Blue Edition Chestpiece Blue Tube...",
            imgSrc: littmann,
        },
        {
            title: "Ready to Ship (Ordered on Sep 10)",
            description: "3M Littmann Classic III Monitoring Stethoscope (5803) - Blue Edition Chestpiece Blue Tube...",
            imgSrc: littmann,
        },
        {
            title: "Ready to Ship (Ordered on Oct 02)",
            description: "3M Littmann Classic III Monitoring Stethoscope (5803) - Blue Edition Chestpiece Blue Tube...",
            imgSrc: littmann,
        },
        {
            title: "Ready to Ship (Ordered on Nov 15)",
            description: "3M Littmann Classic III Monitoring Stethoscope (5803) - Blue Edition Chestpiece Blue Tube...",
            imgSrc: littmann,
        }
    ];
    const itemsHelp = [
        {
            title: "3M Littmann Classic III Monitoring Stethoscope (5803) - Blue Edition....",
            description: "Ordered on 28 August 2024, Delivered on 04 August 2024",
            imgSrc: littmann,
        },
        {
            title: "3M Littmann Classic III Monitoring Stethoscope (5803) - Blue Edition....",
            description: "Ordered on 28 August 2024, Delivered on 04 August 2024",
            imgSrc: littmann,
        },
        {
            title: "3M Littmann Classic III Monitoring Stethoscope (5803) - Blue Edition....",
            description: "Ordered on 28 August 2024, Delivered on 04 August 2024",
            imgSrc: littmann,
        },
        {
            title: "3M Littmann Classic III Monitoring Stethoscope (5803) - Blue Edition....",
            description: "Ordered on 28 August 2024, Delivered on 04 August 2024",
            imgSrc: littmann,
        }
    ];
    const inquiryHistory = [
        {
            title: "Hi Medicabuyer team, I'm Dr. Harshi. I’d like to purchase multiple products at once. Do you offer any ......",
            description: "Sent on 12 Aug 2024 at 3:44 PM",
        },
        {
            title: "Hi Medicabuyer team, I'm Dr. Harshi. I’d like to purchase multiple products at once. Do you offer any ......",
            description: "Sent on 12 Aug 2024 at 3:44 PM",
        },
        {
            title: "Hi Medicabuyer team, I'm Dr. Harshi. I’d like to purchase multiple products at once. Do you offer any ......",
            description: "Sent on 12 Aug 2024 at 3:44 PM",
        },
        {
            title: "Hi Medicabuyer team, I'm Dr. Harshi. I’d like to purchase multiple products at once. Do you offer any ......",
            description: "Sent on 12 Aug 2024 at 3:44 PM",
        }
    ];

    const [isFormVisible, setFormVisible] = useState(false);
    const [isInquiryVisible, setInquiryVisible] = useState(false);

    // Function to open the inquiry form
    const openForm = () => {
        setFormVisible(true);
    };

    // Function to close the inquiry form
    const closeForm = () => {
        setFormVisible(false);
    };
    return (
        <section className="flex justify-center font-poppins bg-[#F3F4F4] min-h-screen">
            <div className='container px-6 py-12'>
                <div className='w-full text-sm md:text-base lg:text-lg xl:text-xl text-justify'>
                    <h1 className="text-2xl xl:text-3xl font-semibold">{myProfileString.title}</h1>
                    <p className="mb-4 text-[#848484]">{myProfileString.desc}</p>
                </div>
                <div className='grid grid-cols-5 gap-4'>
                    <div className='col-span-5 lg:col-span-1 flex justify-between items-center lg:block bg-white p-4 lg:space-y-4 h-min py-8'>
                        {myProfileString.buttons.map((item, index) => (
                            <div
                                key={index}
                                onClick={() => handleButtonClick(item)}
                                className={`cursor-pointer flex items-center gap-1 
                ${selectedButton === item ? 'text-[#2F94A6] font-semibold' : 'text-[#848484]'}`}
                            >
                                <div className='text-xs md:text-sm lg:text-base xl:text-lg'>{item}</div>
                                <img src="" alt="" />
                            </div>
                        ))}
                        {/* <div onClick={handleLogOut}>Log Out</div> */}
                    </div>
                    {confirmLogOut && (
                        <Notification logOut={handleLogOut} onClose={() => setConfirmLogOut(false)} />
                    )}
                    <div className='col-span-5 lg:col-span-4 bg-white p-4'>
                        {selectedButton && (
                            <>
                                {selectedButton === "Personal Details" &&
                                    <div className='p-4'>
                                        <div className='flex justify-between items-center'>
                                            <h2 className="text-xl xl:text-2xl font-semibold"> {selectedButton}</h2>
                                            <button className='border border-[#D0303082] flex items-center gap-0.5 py-2 px-4 rounded-full text-[#D03030]'> <EditIcon className="h-5 w-5 mr-2" /> Edit</button>
                                        </div>
                                        <div className='grid grid-cols-2 gap-4 text-sm md:text-base xl:text-lg'>
                                            <div className='py-2'>
                                                <div className='text-[#848484]'>First Name</div>
                                                <input
                                                    type="text"
                                                    // value={firstName}
                                                    // onChange={(e) => dispatch(setField({ field: 'firstName', value: e.target.value }))}
                                                    placeholder="Enter First Name"
                                                    className="bg-[#F3F4F4] rounded-2xl p-4 w-full outline-none"
                                                />
                                            </div>
                                            <div className='py-2'>
                                                <div className='text-[#848484]'>Last Name</div>
                                                <input
                                                    type="text"
                                                    // value={lastName}
                                                    // onChange={(e) => dispatch(setField({ field: 'lastName', value: e.target.value }))}
                                                    placeholder="Enter Last Name"
                                                    className="bg-[#F3F4F4] rounded-2xl p-4 w-full outline-none"
                                                />
                                            </div>
                                            <div className='py-2'>
                                                <div className='text-[#848484]'>Email ID</div>
                                                <input
                                                    type="email"
                                                    // value={email}
                                                    // onChange={(e) => dispatch(setField({ field: 'email', value: e.target.value }))}
                                                    placeholder="Enter Email"
                                                    className="bg-[#F3F4F4] rounded-2xl p-4 w-full outline-none"
                                                />
                                            </div>
                                            <div className='py-2'>
                                                <div className='text-[#848484]'>User Type</div>
                                                <input
                                                    type="text"
                                                    // value={mobile}
                                                    // onChange={(e) => dispatch(setField({ field: 'mobile', value: e.target.value }))}
                                                    placeholder="Enter User Type"
                                                    className="bg-[#F3F4F4] rounded-2xl p-4 w-full outline-none"
                                                />
                                            </div>
                                            <div className='py-2 col-span-2'>
                                                <div className='text-[#848484]'>Address</div>
                                                <input
                                                    type="text"
                                                    // value={mobile}
                                                    // onChange={(e) => dispatch(setField({ field: 'mobile', value: e.target.value }))}
                                                    placeholder="Enter Address"
                                                    className="bg-[#F3F4F4] rounded-2xl p-4 w-full outline-none"
                                                />
                                            </div>
                                            <div className='py-2'>
                                                <div className='text-[#848484]'>Country</div>
                                                <input
                                                    type="text"
                                                    // value={mobile}
                                                    // onChange={(e) => dispatch(setField({ field: 'mobile', value: e.target.value }))}
                                                    placeholder="Enter Country"
                                                    className="bg-[#F3F4F4] rounded-2xl p-4 w-full outline-none"
                                                />
                                            </div>
                                            <div className='py-2'>
                                                <div className='text-[#848484]'>State</div>
                                                <input
                                                    type="text"
                                                    // value={mobile}
                                                    // onChange={(e) => dispatch(setField({ field: 'mobile', value: e.target.value }))}
                                                    placeholder="Enter State"
                                                    className="bg-[#F3F4F4] rounded-2xl p-4 w-full outline-none"
                                                />
                                            </div>
                                            <div className='py-2'>
                                                <div className='text-[#848484]'>City</div>
                                                <input
                                                    type="text"
                                                    // value={mobile}
                                                    // onChange={(e) => dispatch(setField({ field: 'mobile', value: e.target.value }))}
                                                    placeholder="Enter City"
                                                    className="bg-[#F3F4F4] rounded-2xl p-4 w-full outline-none"
                                                />
                                            </div>
                                            <div className='py-2'>
                                                <div className='text-[#848484]'>Pincode/Zipcode</div>
                                                <input
                                                    type="text"
                                                    // value={mobile}
                                                    // onChange={(e) => dispatch(setField({ field: 'mobile', value: e.target.value }))}
                                                    placeholder="Enter Pincode/Zipcode"
                                                    className="bg-[#F3F4F4] rounded-2xl p-4 w-full outline-none"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                }
                                {selectedButton === "My Orders" &&
                                    <div className='p-4'>
                                        <div className='flex justify-between items-center'>
                                            <h2 className="text-xl xl:text-2xl font-semibold"> {selectedButton}</h2>
                                            <div className='flex items-center'>
                                                {myProfileString.orderCategory.map((item) => (
                                                    <div className={`px-4 py-2  border-b cursor-pointer 
                                                        ${order === item
                                                            ? 'text-[#2F94A6] font-semibold border-[#2F94A6]'
                                                            : 'text-[#848484] border-[#84848459]'}
                                                  `} onClick={() => handleOrder(item)}>{item}</div>
                                                ))}
                                            </div>
                                        </div>
                                        <div className='space-y-4 py-4'>
                                            {itemsOrder.map((item, index) => (
                                                <div className='flex items-start justify-between h-24' key={index} onClick={() => handleOrderDetails(item)}>
                                                    <div className='w-[10%] h-full'>
                                                        <img src={item.imgSrc} alt="" className='w-full h-full bg-cover object-cover' />
                                                    </div>
                                                    <div className='w-[80%] px-4 py-2'>
                                                        <div className='font-semibold text-base md:text-lg xl:text-xl'>{item.title}</div>
                                                        <div className='text-[#848484] text-sm md:text-base xl:text-lg'>{item.description}</div>
                                                    </div>
                                                    <div className='w-[10%] relative h-full'>
                                                        <img src={rightArrow} alt="" className='absolute top-[45%]' />
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                }
                                {selectedButton === "My Wishlist" &&
                                    <div className='p-4'>
                                        <div className='flex justify-between items-center'>
                                            <h2 className="text-xl xl:text-2xl font-semibold"> {selectedButton}</h2>
                                        </div>
                                        <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 -mx-4'>
                                            {items.map(item => (
                                                <CommonBigOffer key={item.id} data={item} img={injections} like={like} onRemove={() => handleRemove(item.id)} />
                                            ))}
                                        </div>
                                        {/* {items.length === 0 ? (
                                            <p>Your wishlist is empty.</p>
                                        ) : (
                                            <ul>
                                                {items.map(item => (
                                                    <li key={item.id} className="wishlist-item">
                                                        <div className="item-info">
                                                            <h3>{item.title}</h3>
                                                            <p>{item.price}</p>
                                                            <p>{item.description}</p>
                                                        </div>
                                                        <button onClick={() => handleRemove(item.id)}>Remove</button>
                                                    </li>
                                                ))}
                                            </ul>
                                        )} */}
                                    </div>
                                }
                                {selectedButton === "FAQs" &&
                                    <>
                                        <div className='p-4'>
                                            <div className='flex justify-between items-center'>
                                                <h2 className="text-xl xl:text-2xl font-semibold">Frequently Asked Questions (FAQs)</h2>
                                            </div>
                                            <div className='py-4'>
                                                {myProfileString.FAQs.map((faq, index) => (
                                                    <div key={index} className="faq-item mb-4 border-b border-[#84848440] py-4">
                                                        <div
                                                            onClick={() => toggleAnswer(index)}
                                                            className="cursor-pointer text-lg font-medium "
                                                        >
                                                            {faq.question}
                                                        </div>
                                                        {expandedQuestion === index && (
                                                            <div className="mt-2 text-[#848484]">
                                                                {faq.answer}
                                                            </div>
                                                        )}
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </>
                                }
                                {selectedButton === "Help" &&
                                    <div className='p-4'>
                                        <div className='flex justify-between items-center'>
                                            <h2 className="text-xl xl:text-2xl font-semibold"> {selectedButton}</h2>
                                            <div className='flex items-center'>
                                                {myProfileString.helpHistory.map((item) => (
                                                    <div className={`px-4 py-2  border-b cursor-pointer 
                                                        ${help === item
                                                            ? 'text-[#2F94A6] font-semibold border-[#2F94A6]'
                                                            : 'text-[#848484] border-[#84848459]'}
                                                  `} onClick={() => handleOrder(item)}>{item}</div>
                                                ))}
                                            </div>
                                        </div>
                                        <div className='space-y-4 py-4'>
                                            {help === "Order Inquiry History" ?
                                                <>{
                                                    itemsHelp.map((item, index) => (
                                                        <div className='flex items-start justify-between h-24' key={index}>
                                                            <div className='w-[10%] h-full'>
                                                                <img src={item.imgSrc} alt="" className='w-full h-full bg-cover object-cover' />
                                                            </div>
                                                            <div className='w-[70%] px-4 py-2'>
                                                                <div className='font-semibold text-base md:text-lg xl:text-xl'>{item.title}</div>
                                                                <div className='text-[#848484] text-sm md:text-base xl:text-lg'>{item.description}</div>
                                                            </div>
                                                            <div className='w-[20%] text-right relative h-full'>
                                                                <button className='rounded-full border border-[#EF2D2D] text-[#EF2D2D] p-2'>Send Inquiry</button>
                                                            </div>
                                                        </div>
                                                    ))
                                                }</>
                                                :
                                                (<>
                                                    {inquiryHistory.map((item, index) => (
                                                        <div className='flex items-start justify-between h-24 cursor-pointer ' key={index}
                                                            onClick={handleInquiryDetails}>
                                                            <div className='w-[90%] px-4 py-2'>
                                                                <div className='font-semibold text-base md:text-lg xl:text-xl'>{item.title}</div>
                                                                <div className='text-[#848484] text-sm md:text-base xl:text-lg'>{item.description}</div>
                                                            </div>
                                                            <div className='w-[10%] relative h-full flex items-center justify-end'>
                                                                <img src={rightArrow} alt="" className='absolute top-[45%]' />
                                                            </div>
                                                        </div>
                                                    ))}
                                                </>)
                                            }
                                        </div>
                                        <div className='text-right' onClick={openForm}>
                                            <button className='rounded-full p-2 border border-[#2F94A6] text-[#2F94A6]'>+ Create New Inquiry</button>
                                        </div>
                                        {isFormVisible && (
                                            <CreateNewInquiry onClose={closeForm} title={"Create New Inquiry"} disc={"Not getting solutions! No worries, we’re here to assist you 24/7! Simply type your question below and submit it. We’ll resolve your problem as quickly as possible."} />
                                        )}
                                    </div>
                                }
                            </>
                        )}
                    </div>
                </div>
            </div>
        </section>
    );
}

export default MyProfile;
