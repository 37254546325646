import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import upCrossArrow from './../../assets/upCrossArrow.png'
import { useDispatch, useSelector } from 'react-redux';
import { addToWishlist, removeFromWishlist } from '../../reduxStore/wishlistSlice';
import { setSignInStatus } from '../../reduxStore/modalSlice';
const CommonBigOffer = ({ data, dataOrg, price, off, name, img, like, font, onRemove }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const wishlist = useSelector(state => state.wishlist.items); // Get wishlist from Redux store
    const isItemInWishlist = wishlist.some(item => item.id === data.id); // Check if item is already in wishlist

    const [liked, setLiked] = useState(false); // State to toggle like status
    // Check if the current item is in the wishlist on component mount
    useEffect(() => {
        const isLiked = wishlist.some(item => item.id === data.id);
        setLiked(isLiked);
    }, [wishlist, data.id]);
    // Convert price and off to integers (or float for decimal prices)
    const priceInt = parseFloat(price); // Use parseFloat in case price has decimal points
    const offInt = parseFloat(off);
    // Check if both price and off are valid numbers
    if (isNaN(priceInt) || isNaN(offInt)) {
        return <div>Invalid price or discount value.</div>; // Return an error message or fallback
    }
    // Calculate the discounted price
    const discPrice = priceInt - (priceInt * (offInt / 100));
    // const handleLikeClick = () => {
    //     if (liked) {
    //         dispatch(removeFromWishlist({ id: data.id })); // Remove from wishlist
    //     } else {
    //         dispatch(addToWishlist(data)); // Add to wishlist
    //         console.log(data);
    //     }
    //     setLiked(!liked); // Toggle liked state
    // };
    const handleAddToWishlist = () => {
        if (setSignInStatus) {
            if (isItemInWishlist) {
                // Remove the item if it's already in the wishlist
                dispatch(removeFromWishlist(data.id));
            } else {
                // Add the item to the wishlist if it's not there
                dispatch(addToWishlist(data));
                console.log(data)
            }
        }
    };

    const handleProductPage = () => {
        navigate('/mainProduct', {
            state: {
                data: dataOrg
            }
        });
    };
    return (
        <div className='px-4 py-4 flex flex-col h-[400px] 2xl:h-[450px] '>
            <div className='rounded-xl pb-4 overflow-hidden relative h-[80%] lg:h-[75%]'>
                <img src={img} alt="" className='w-full h-full' onClick={() => console.log(dataOrg)} crossOrigin="anonymous" />
                {like &&
                    <div
                        className={`absolute top-4 right-2 ring-white p-1 rounded-full transition-transform transform duration-300 ${liked ? 'scale-110' : 'scale-100'}`}
                        onClick={handleAddToWishlist} // Add click event listener
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            fill={liked ? 'red' : 'white'} // Apply red fill when liked
                            className="w-6 h-6"
                        >
                            <path d="M12 21.35l-1.45-1.32C5.4 15.36 2 12.28 2 8.5 2 5.42 4.42 3 7.5 3c1.74 0 3.41.81 4.5 2.09C13.09 3.81 14.76 3 16.5 3 19.58 3 22 5.42 22 8.5c0 3.78-3.4 6.86-8.55 11.54L12 21.35z" />
                        </svg>
                    </div>
                }
                {data.discountLabel &&
                    <div className='absolute top-4 left-0  text-white flex'>
                        <div className='bg-[#D03030] py-2 pl-2'>{data.discountLabel}</div>
                        <div className="w-0 h-full border-t-[20px] border-b-[20px] border-r-[10px] border-r-transparent border-t-transparent border-b-transparent border-l-[10px] border-l-[#D03030]"></div>
                    </div>

                }
            </div>
            <div className='h-[20%] lg:h-[25%]'>
                <h3
                    className={`text-base md:text-base lg:text-lg xl:text-xl  ${font ? font : "font-semibold"} truncate overflow-hidden whitespace-nowrap`}
                    title={name}  // Optional: Shows full name on hover
                >
                    {name}
                </h3>
                <div className='text-[#848484] text-xs md:text-sm lg:text-[0.9rem]'>{data.description}</div>
                <div className='py-2 '>
                    {data.price ? (
                        <div>
                            <div className='flex items-center gap-1'>
                                <p className='text-[#EF2D2D] font-semibold text-sm md:text-base lg:text-lg'>{discPrice ? discPrice : data.price}</p>
                                <p className='text-[#848484] text-xs md:text-sm lg:text-[0.8.5rem] line-through'>{price}</p>
                                <p className='text-[#43B678] text-xs md:text-sm lg:text-[0.8rem]'>{off}</p>
                            </div>
                            {data.buyNow &&
                                <div className='flex items-center gap-2 pt-2'>
                                    <button onClick={handleProductPage} className='rounded-full border border-[#1A444C] hover:bg-[#1A444C] hover:text-white text-[#1A444C] font-light text-sm px-2 py-1'>
                                        {data.buyNow}
                                    </button>
                                    <button className='rounded-full hover:bg-[#1A444C] border border-[#1A444C] hover:text-white font-light text-sm px-2 py-1'>
                                        {data.addToCart}
                                    </button>
                                </div>
                            }
                            {data.addToCartBtn &&
                                <div className='flex items-center gap-2'>
                                    <button className='hover:bg-[#1A444C] hover:text-white border border-[#1A444C] text-[#1A444C] font-light text-sm px-2 py-1'>{data.addToCartBtn}</button>
                                </div>
                            }
                            {data.viewDetails &&
                                <div className='flex items-center gap-2'>
                                    <button
                                        onClick={handleProductPage}
                                        className='hover:bg-[#1A444C] hover:text-white border border-[#1A444C] text-[#1A444C] font-light text-sm px-2 py-1'>{data.viewDetails}</button>
                                </div>
                            }
                        </div>
                    ) : (<>
                        {data.getQuoteBtn ? (
                            <button className='rounded-full border border-[#1A444C] hover:bg-[#1A444C] hover:text-white text-[#1A444C]  font-light text-sm px-2 py-1'>{data.getQuoteBtn}</button>
                        ) : (
                            <button
                                // onClick={handleProductPage} 
                                className='rounded-full border border-[#1A444C] hover:bg-[#1A444C] hover:text-white text-[#1A444C]  font-light text-sm px-2 py-1 flex items-center gap-1'>
                                {data.explore}
                                <img src={upCrossArrow} alt="" />
                            </button>
                        )}
                    </>)}
                </div>
            </div >
        </div>
    )
}

export default CommonBigOffer
