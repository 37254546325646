import React, { useEffect, useRef, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCategories } from '../reduxStore/categorySlice';
import { setInitialTitle } from '../reduxStore/subCategorySlice';
import { useNavigate } from 'react-router-dom';
import downArrow from './../assets/downArrow.png';
import zipcode from './../assets/zipCodeIcon.png';

const Category = () => {
    const [isOpen, setIsOpen] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const categories = useSelector((state) => state.categories.items);
    const categoryStatus = useSelector((state) => state.categories.status);
    const categoryError = useSelector((state) => state.categories.error);
    const initialTitle = useSelector((state) => state.subCategories.initialTitle);
    const menuRef = useRef(null);

    const toggleMenu = () => {
        setIsOpen((prev) => !prev);
    };

    const handleClose = useCallback(() => {
        setIsOpen(false);
    }, []);

    const handleSubCategory = useCallback(
        (name) => {
            navigate("/subCategory", { state: { categoryName: name } });
            dispatch(setInitialTitle(name));
        },
        [navigate, dispatch]
    );

    useEffect(() => {
        const handleClickOutside = (e) => {
            if (menuRef.current && !menuRef.current.contains(e.target)) {
                setIsOpen(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    useEffect(() => {
        dispatch(fetchCategories());
    }, [dispatch]);

    return (
        <section className="flex justify-center bg-darkBlue text-white font-poppins">
            <div className="container py-8 px-4 md:px-0">
                <div className="flex flex-col xl:flex-row gap-2 xl:gap-0 items-center justify-between w-full">
                    <div className="flex items-center w-full xl:w-[80%] 2xl:w-[75%] ">
                        <div
                            onClick={toggleMenu}
                            className="py-1 pr-2  border-r-2 text-xs sm:text-sm lg:text-[0.8rem] border-white  cursor-pointer"
                        >
                            Categories
                        </div>
                        <div
                            ref={menuRef}
                            className="mx-2 flex justify-between items-center gap-1 2xl:gap-2 min-w-[900px] overflow-x-auto w-full"
                            style={{
                                scrollbarWidth: 'none', /* Firefox */
                                msOverflowStyle: 'none', /* IE and Edge */
                            }}
                        >
                            <style>{`
        /* Chrome, Safari, Opera */
        div::-webkit-scrollbar {
            display: none;
        }
    `}</style>

                            {categoryStatus === "loading" && <p>Loading categories...</p>}
                            {categoryError && <p>Error: {categoryError}</p>}
                            {categories &&
                                categories.map((item) => (
                                    <div
                                        key={item._id}
                                        onClick={() => handleSubCategory(item.name)}
                                        className="rounded-full text-center text-[0.75rem] xl:text-sm border-[#848484]/50 border-[1px] lg:p-1 px-1 flex items-center justify-center max-h-max w-full font-light cursor-pointer"
                                    >
                                        {item.name}
                                    </div>
                                ))}
                        </div>

                    </div>
                    <div className="flex items-center gap-1 2xl:gap-1 text-xs sm:text-sm lg:text-[0.8rem] w-full xl:w-[20%] 2xl:w-[25%] ">
                        <div className="rounded-full border-[#848484]/50 border-[1px] p-1 px-2 sm:px-3 flex items-center justify-center gap-1 sm:gap-2 font-light h-full">
                            Language <img src={downArrow} alt="down arrow" />
                        </div>
                        <div className="rounded-full border-[#848484]/50 border-[1px] p-1 px-2 sm:px-3 flex items-center justify-center gap-1 sm:gap-2 font-light">
                            India <img src={downArrow} alt="down arrow" />
                        </div>
                        <div className="rounded-full border-[#848484]/50 border-[1px] p-1  px-2 md:px-3 flex items-center justify-center w-full gap-1 md:gap-2 bg-[#848484]/50">
                            <img src={zipcode} alt="zip code icon" />
                            <input type="text" placeholder="Enter Zipcode" className="outline-none bg-transparent w-full" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Category;