import { Link, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchProductBySubCategory,
  fetchProducts,
  fetchSubCategories,
} from "../../apiEndPoint";
import {
  fetchSubCategoriesAsync,
  setInitialTitle,
} from "../../reduxStore/subCategorySlice";
import {
  fetchProductsAsync,
  fetchProductsBySubCategoryAsync,
  setFilteredProductsList,
} from "../../reduxStore/productSlice";

import Category from "../Category";
import SubCategoryComponent from "./SubCategoryComponent";
import CommonBigOffer from "../bigOffer/CommonBigOffer";
import mriMachine from "./../../assets/mriMachine.png";
import shopByCategory from "./shopByCategoryString";

const SubCategory = () => {
  const location = useLocation();
  const { categoryName, subCategoryName } = location.state || {};
  const dispatch = useDispatch();

  // State and Redux Selectors
  const { items: subCategories, initialTitle } = useSelector(
    (state) => state.subCategories
  );
  const filteredProductsList = useSelector(
    (state) => state.products.filteredItems
  );
  const [initialSubCategory, setInitialSubCategory] = useState(
    subCategoryName || ""
  );
  const [selectedSubCategoryId, setSelectedSubCategoryId] = useState(null);
  const [isLoading, setIsLoading] = useState(true); // Add loading state

  // Filtered list of subcategories by selected category name
  const filteredItems = subCategories.filter(
    (subCategory) => subCategory.category.name === initialTitle
  );

  // Fetch initial data
  useEffect(() => {
    dispatch(fetchSubCategoriesAsync());
    dispatch(fetchProductsAsync());
  }, [dispatch]);

  // Set initial subcategory based on location state or filtered items
  useEffect(() => {
    if (filteredItems.length > 0) {
      const initialSubCategoryName = subCategoryName || filteredItems[0].name;
      setInitialSubCategory(initialSubCategoryName);
      setSelectedSubCategoryId(
        filteredItems.find(
          (subCategory) => subCategory.name === initialSubCategoryName
        )?._id
      );
    }
  }, [filteredItems, subCategoryName]);
  useEffect(() => {
    if (selectedSubCategoryId) {
      dispatch(fetchProductsBySubCategoryAsync(selectedSubCategoryId));
    }
  }, [dispatch, selectedSubCategoryId]);

  return (
    <>
      <Category />
      <section className="flex justify-center font-poppins">
        <div className="container px-6 py-12 relative">
          {/* Breadcrumb Navigation */}
          <div className="flex items-center gap-4 py-4">
            <Link to="/" className="text-[#848484]">
              Home
            </Link>
            <Link to="/allShopCategory" className="text-[#848484]">
              / Shop by category
            </Link>
            <span className="text-[#848484]">/ Medical Devices</span>
          </div>

          {/* Title Section */}
          <div className="w-full flex justify-between items-center">
            <h2 className="font-semibold text-2xl xl:text-3xl">
              {`${shopByCategory.subCategoryTitle} ${initialTitle}`}
            </h2>
          </div>

          {/* Subcategory Selection */}
          <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-8 py-4 gap-4">
            {filteredItems.map((subCategory) => (
              <div
                key={subCategory._id}
                onClick={() => setSelectedSubCategoryId(subCategory._id)}
              >
                <SubCategoryComponent
                  img={`${process.env.REACT_APP_BASE_URL}${subCategory.image}`}
                  name={subCategory.name}
                  isSelected={selectedSubCategoryId === subCategory._id}
                />
              </div>
            ))}
          </div>

          {/* Products List */}
          <div className="py-20">
            <div className="w-full flex justify-between items-center">
              <div>
                <h2 className="font-semibold text-2xl xl:text-3xl">
                  {initialSubCategory}
                </h2>
                <div className="text-[#848484] text-xs md:text-sm lg:text-base">
                  {shopByCategory.desc}
                </div>
              </div>
            </div>

            <div className="py-8 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 ">
              {filteredProductsList.length > 0 ? (
                filteredProductsList.map((product) => (
                  <CommonBigOffer
                    key={product._id}
                    img={`${process.env.REACT_APP_BASE_URL}/${product.image}`}
                    name={product.name}
                    price={product.variation[0].price}
                    off={`${product.variation[0].discount}% off`}
                    data={shopByCategory.medicalDeviceArray.thermoMeter}
                    dataOrg={product}
                  />
                ))
              ) : (
                <p>No products found</p>
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SubCategory;
