import React from 'react'
import { motion } from 'framer-motion'

// Define the animation variant for scroll-triggered rotation along the y-axis
const cardVariants = {
    offscreen: {
        y: 100, // Start position below the viewport
        opacity: 0,
    },
    onscreen: {
        y: 0, // Animates back to its original position
        opacity: 1,
        transition: {
            type: "spring",
            bounce: 0.4,
            duration: 0.8,
        },
    }
}

const Common = ({ img, title, description }) => {
    return (
        <motion.div
            className='bg-[#EBF7F9] rounded-ss-2xl xl:rounded-ss-[3rem] rounded-ee-2xl xl:rounded-ee-[3rem]'
            initial="offscreen"
            whileInView="onscreen"
            viewport={{ once: true, amount: 0.5 }} // Trigger animation when half of the card is in view
            variants={cardVariants}
        >
            <div className='p-2 lg:p-4 flex flex-col justify-center items-center'>
                <div className='rounded-full p-6 xl:p-8 2xl:p-12 bg-white my-8'>
                    <div className='w-14 sm:w-20 h-14 sm:h-20'>
                        <img src={img} alt="" className='w-full h-full' />
                    </div>
                </div>
                <div className='text-base md:text-lg xl:text-xl text-center font-semibold'>{title}</div>
                <div className='text-xs md:text-sm xl:text-base text-center text-[#848484] line-clamp-2 overflow-y-auto max-h-12'>{description}</div>
            </div>
        </motion.div>
    )
}

export default Common
