// src/components/AwardsAndRecognition.js
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAwardsAndRecognition } from './../../reduxStore/awardsSlice';
import CommonAward from './CommonAward';

const AwardsAndRecognition = () => {
    const dispatch = useDispatch();
    const { data, loading, error } = useSelector((state) => state.awards);

    useEffect(() => {
        dispatch(getAwardsAndRecognition());
    }, [dispatch]);

    if (loading) {
        return <p>Loading...</p>;
    }

    if (error) {
        return <p>Error: {error.message}</p>;
    }

    return (
        <section className="flex justify-center font-poppins">
            <div className="container px-6 py-12">
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 xl:gap-8 2xl:gap-12 w-full py-4">
                    <div>
                        <h2 className="font-semibold text-2xl xl:text-3xl">Awards and Recognition</h2>
                        <p className="text-[#848484] text-xs md:text-sm lg:text-base">Our awards and recognitions showcase our achievements.</p>
                    </div>
                    {data.map((award, index) => (
                        <div key={index}>
                            <CommonAward title={award.title} description={award.description} />
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default AwardsAndRecognition;
