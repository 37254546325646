import React, { useEffect, useState } from "react";
import CommonBigOffer from "./CommonBigOffer";
import bigOffer from "./bigOfferString";
import { GrLinkNext, GrLinkPrevious } from "react-icons/gr";
import Slider from "react-slick";
import { fetchProductsAsync } from "../../reduxStore/productSlice";
import { useDispatch, useSelector } from "react-redux";
import like from "./../../assets/like.svg";
import shopByCategory from "../shopByCategory/shopByCategoryString";
import { fetchBigOffer } from "../../apiEndPoint";

// Custom Arrow Components
const PrevArrow = ({ onClick }) => (
  <button
    onClick={onClick}
    className="absolute left-0 top-[38%] md:top-[31%] lg:top-[29%] xl:top-[35%] 2xl:top-[36%] transform -translate-y-1/2 bg-white p-3 rounded-full shadow-lg z-10 hover:bg-[#1A444C] text-[#1A444C] hover:text-[#E1DFD7]"
    aria-label="Previous"
  >
    <GrLinkPrevious />
  </button>
);

const NextArrow = ({ onClick }) => (
  <button
    onClick={onClick}
    className="absolute right-0 top-[38%] md:top-[31%] lg:top-[29%] xl:top-[35%] 2xl:top-[36%] transform -translate-y-1/2 bg-white p-3 rounded-full shadow-lg z-10 hover:bg-[#1A444C] text-[#1A444C] hover:text-[#E1DFD7]"
    aria-label="Next"
  >
    <GrLinkNext />
  </button>
);

const MainBigOffer = () => {
  const [bigOfferProducts, setBigOfferProducts] = useState([]);
  const {
    items: products,
    loading,
    error,
  } = useSelector((state) => state.products);

  useEffect(() => {
    const fetchDataBigOffer = async () => {
      try {
        const response = await fetchBigOffer();
        setBigOfferProducts(response);
      } catch (error) {
        console.log(error);
      }
    };
    fetchDataBigOffer();
  }, []);

  // Slick slider settings
  const settings = {
    dots: false,
    infinite: true,
    speed: 300,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      { breakpoint: 1536, settings: { slidesToShow: 4 } },
      { breakpoint: 1280, settings: { slidesToShow: 4 } },
      { breakpoint: 1024, settings: { slidesToShow: 3 } },
      { breakpoint: 768, settings: { slidesToShow: 2 } },
      { breakpoint: 480, settings: { slidesToShow: 1 } },
    ],
  };

  return (
    <section className="flex justify-center font-poppins">
      <div className="container px-6 py-12">
        <div className="w-full flex justify-between items-center">
          <div>
            <h2 className="font-semibold text-2xl xl:text-3xl">
              {bigOffer.title}
            </h2>
            <div className="text-[#848484] text-xs md:text-sm lg:text-base">
              {bigOffer.desc}
            </div>
          </div>
          <div>
            <button className="underline text-[#1A444C] text-xs md:text-base lg:text-lg xl:text-xl">
              {bigOffer.seeAll}
            </button>
          </div>
        </div>

        {/* Slick Slider Section */}
        {loading ? (
          <p>Loading products...</p>
        ) : error ? (
          <p className="text-red-500">Error loading products: {error}</p>
        ) : bigOfferProducts.length === 0 ? (
          <p>No products available at the moment.</p>
        ) : (
          <div className="py-4">
            {bigOfferProducts.length > 3 ? (
              <Slider {...settings} className="relative">
                {bigOfferProducts.map((product) => (
                  <CommonBigOffer
                    key={product._id}
                    like={like}
                    img={`${process.env.REACT_APP_BASE_URL}/${product.image}`}
                    name={product.name}
                    price={product.variation[0].price}
                    off={`${product.variation[0].discount}% off`}
                    data={shopByCategory.medicalDeviceArray.thermoMeter}
                    dataOrg={product}
                  />
                ))}
              </Slider>
            ) : (
              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
                {bigOfferProducts.map((product) => (
                  <CommonBigOffer
                    key={product._id}
                    like={like}
                    img={`${process.env.REACT_APP_BASE_URL}/${product.image}`}
                    name={product.name}
                    price={product.variation[0].price}
                    off={`${product.variation[0].discount}% off`}
                    data={shopByCategory.medicalDeviceArray.thermoMeter}
                    dataOrg={product}
                  />
                ))
                }
              </div>
            )}
          </div>
        )}
      </div>
    </section>
  );
};

export default MainBigOffer;
