import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    setField,
    setErrors,
    clearErrors,
    resetProfileForm,
    fetchCountries,
    fetchStates,
    fetchCities
} from './../../../reduxStore/profileSlice'; // Adjust path
import cross from './../../../assets/cross.png';
import backArrow from './../../../assets/backButton.png';
import KYCForm from './KYCForm';
import ActivationSuccess from './ActivationSuccess';

const category = [
    { title: "GST Number" },
    { title: "Drug License" },
    { title: "MCI (Medical council of India Registration)" },
    { title: "VCI (Veterinary council of India Registration)" },
    { title: "DCI (Dental council of India Registration)" },
    { title: "I’m a medical staff/student buying products for educational/professional use" },
];

const CompleteProfile = ({ refs, successForm }) => {
    const dispatch = useDispatch();
    const { firstName, lastName, email, mobile, whatsapp, address, errors, country, state, city, countries, states, cities } = useSelector((state) => state.profile);

    const [selectedCategory, setSelectedCategory] = useState("");
    const [submittedCategory, setSubmittedCategory] = useState(''); // For the category when submit button is pressed
    const [showCategoryForm, setShowCategoryForm] = useState(false);

    useEffect(() => {
        dispatch(fetchCountries());
    }, [dispatch]);

    useEffect(() => {
        if (country) dispatch(fetchStates(country));
    }, [country, dispatch]);

    useEffect(() => {
        if (state) dispatch(fetchCities(state));
    }, [state, dispatch]);

    const handleChange = (event) => {
        setSelectedCategory(event.target.value);
    };

    const validateForm = () => {
        const newErrors = {};
        if (!firstName) newErrors.firstName = "First Name is required";
        if (!lastName) newErrors.lastName = "Last Name is required";
        if (!email) {
            newErrors.email = "Email is required";
        } else if (!/\S+@\S+\.\S+/.test(email)) {
            newErrors.email = "Email is invalid";
        }
        if (!mobile) {
            newErrors.mobile = "Mobile Number is required";
        } else if (!/^\d{10}$/.test(mobile)) {
            newErrors.mobile = "Mobile Number should contain only digits and must be 10 digits";
        }
        if (!whatsapp) newErrors.whatsapp = "WhatsApp Number is required";
        if (!address) newErrors.address = "Address is required";
        if (!country) newErrors.country = "Country is required";
        if (!state) newErrors.state = "State is required";
        if (!city) newErrors.city = "City is required";
        if (!selectedCategory) newErrors.category = "Please select a category";

        return newErrors;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const validationErrors = validateForm();
        if (Object.keys(validationErrors).length) {
            dispatch(setErrors(validationErrors));
        } else {
            dispatch(clearErrors());
            // Handle successful form submission here
            console.log("Form Submitted!", { firstName, lastName, email, mobile, whatsapp, address, country, state, city });
            setSubmittedCategory(selectedCategory); // Only update the submitted category on submit
            setShowCategoryForm(true); // Show category-specific form
        }
    };

    const renderCategoryForm = () => {
        switch (selectedCategory) {
            case "GST Number":
                return <div>GST Number Form</div>;
            case "Drug License":
                return <div>Drug License Form</div>;
            case "MCI (Medical council of India Registration)":
                return <div>MCI Form</div>;
            case "VCI (Veterinary council of India Registration)":
                return <div>VCI Form</div>;
            case "DCI (Dental council of India Registration)":
                return <div>DCI Form</div>;
            case "I’m a medical staff/student buying products for educational/professional use":
                return <KYCForm title={"medical staff/student"} />;
            default:
                return null;
        }
    };


    const onClose = () => {
        // Handle close logic
    };

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-sm flex flex-col justify-center items-center z-50">
            <div className='flex justify-end items-end w-[90%] py-1'>
                <button className='bg-white rounded-full p-2' onClick={onClose}>
                    <img src={cross} alt="" className='lg:size-3' />
                </button>
            </div>
            <div ref={refs} className="bg-white rounded-3xl shadow-lg w-[90%] h-auto max-h-[90%] overflow-y-auto relative">
                <div className='bg-[#FFEBEB] text-[#EF2D2D] py-2'>
                    <marquee behavior="" direction="">
                        • Limited time offer: Register now and get 10% off on your first order! • Limited time offer: Up to 20% off on medical equipment!
                    </marquee>
                </div>
                <div className=''>
                    <div className='flex items-center gap-2 px-4 py-4 md:px-8 md:py-8'>
                        <div><img src={backArrow} alt="" /></div>
                        <h3 className='font-semibold text-lg md:text-xl lg:text-2xl xl:text-3xl 2xl:text-4xl'>Complete Profile</h3>
                    </div>
                    <form className='pb-4 md:pb-8' onSubmit={handleSubmit}>
                        <div className='w-full bg-[#EBF7F9] p-4 md:p-8'>
                            <div className='font-semibold text-[#2F94A6] uppercase text-base lg:text-lg xl:text-xl 2xl:text-2xl'>Basic Information</div>
                            <div className='text-sm lg:text-base xl:text-lg text-[#2F94A6]/50'>Add below details to proceed creating your MedicaBuyer account.</div>
                        </div>
                        <div className='grid grid-cols-1 md:grid-cols-2 gap-4 p-4 md:p-8'>
                            <div className='py-2'>
                                <div className='text-[#848484]'>First Name</div>
                                <input
                                    type="text"
                                    value={firstName}
                                    onChange={(e) => dispatch(setField({ field: 'firstName', value: e.target.value }))}
                                    placeholder="Enter First Name"
                                    className={`bg-[#F3F4F4] rounded-2xl p-4 w-full outline-none ${errors.firstName ? 'border border-red-500' : ''}`}
                                />
                                {errors.firstName && <p className="text-red-500 text-xs">{errors.firstName}</p>}
                            </div>
                            <div className='py-2'>
                                <div className='text-[#848484]'>Last Name</div>
                                <input
                                    type="text"
                                    value={lastName}
                                    onChange={(e) => dispatch(setField({ field: 'lastName', value: e.target.value }))}
                                    placeholder="Enter Last Name"
                                    className={`bg-[#F3F4F4] rounded-2xl p-4 w-full outline-none ${errors.lastName ? 'border border-red-500' : ''}`}
                                />
                                {errors.lastName && <p className="text-red-500 text-xs">{errors.lastName}</p>}
                            </div>
                            <div className='py-2'>
                                <div className='text-[#848484]'>Email ID</div>
                                <input
                                    type="email"
                                    value={email}
                                    onChange={(e) => dispatch(setField({ field: 'email', value: e.target.value }))}
                                    placeholder="Enter Email"
                                    className={`bg-[#F3F4F4] rounded-2xl p-4 w-full outline-none ${errors.email ? 'border border-red-500' : ''}`}
                                />
                                {errors.email && <p className="text-red-500 text-xs">{errors.email}</p>}
                            </div>
                            <div className='py-2'>
                                <div className='text-[#848484]'>Mobile Number</div>
                                <input
                                    type="text"
                                    value={mobile}
                                    onChange={(e) => dispatch(setField({ field: 'mobile', value: e.target.value }))}
                                    placeholder="Enter Mobile Number"
                                    className={`bg-[#F3F4F4] rounded-2xl p-4 w-full outline-none ${errors.mobile ? 'border border-red-500' : ''}`}
                                />
                                {errors.mobile && <p className="text-red-500 text-xs">{errors.mobile}</p>}
                            </div>
                            <div className='py-2'>
                                <div className='text-[#848484]'>WhatsApp Number</div>
                                <input
                                    type="text"
                                    value={whatsapp}
                                    onChange={(e) => dispatch(setField({ field: 'whatsapp', value: e.target.value }))}
                                    placeholder="Enter WhatsApp Number"
                                    className={`bg-[#F3F4F4] rounded-2xl p-4 w-full outline-none ${errors.whatsapp ? 'border border-red-500' : ''}`}
                                />
                                {errors.whatsapp && <p className="text-red-500 text-xs">{errors.whatsapp}</p>}
                            </div>
                            <div className='py-2'>
                                <div className='text-[#848484]'>Address</div>
                                <input
                                    type="text"
                                    value={address}
                                    onChange={(e) => dispatch(setField({ field: 'address', value: e.target.value }))}
                                    placeholder="Enter Address"
                                    className={`bg-[#F3F4F4] rounded-2xl p-4 w-full outline-none ${errors.address ? 'border border-red-500' : ''}`}
                                />
                                {errors.address && <p className="text-red-500 text-xs">{errors.address}</p>}
                            </div>
                            <div className='py-2'>
                                <div className='text-[#848484]'>Country</div>
                                <select
                                    value={country}
                                    onChange={(e) => dispatch(setField({ field: 'country', value: e.target.value }))}
                                    className={`bg-[#F3F4F4] rounded-2xl p-4 w-full outline-none ${errors.country ? 'border border-red-500' : ''}`}
                                >
                                    <option value="">Select Country</option>
                                    {/* {countries.map((countryItem) => ( */}
                                    <option
                                    // key={countryItem.id} value={countryItem.id}
                                    >
                                        {/* {countryItem.name} */}
                                        India
                                    </option>
                                    {/* ))} */}
                                </select>
                                {errors.country && <p className="text-red-500 text-xs">{errors.country}</p>}
                            </div>

                            <div className='py-2'>
                                <div className='text-[#848484]'>State</div>
                                <select
                                    value={state}
                                    onChange={(e) => dispatch(setField({ field: 'state', value: e.target.value }))}
                                    className={`bg-[#F3F4F4] rounded-2xl p-4 w-full outline-none ${errors.state ? 'border border-red-500' : ''}`}
                                    disabled={!country}
                                >
                                    <option value="">Select State</option>
                                    {/* {states.map((stateItem) => ( */}
                                    <option
                                    // key={stateItem.id} value={stateItem.id}
                                    >
                                        {/* {stateItem.name} */}
                                        Gujarat
                                    </option>
                                    {/* ))} */}
                                </select>
                                {errors.state && <p className="text-red-500 text-xs">{errors.state}</p>}
                            </div>

                            <div className='py-2'>
                                <div className='text-[#848484]'>City</div>
                                <select
                                    value={city}
                                    onChange={(e) => dispatch(setField({ field: 'city', value: e.target.value }))}
                                    className={`bg-[#F3F4F4] rounded-2xl p-4 w-full outline-none ${errors.city ? 'border border-red-500' : ''}`}
                                    disabled={!state}
                                >
                                    <option value="">Select City</option>
                                    {/* {cities.map((cityItem) => ( */}
                                    <option
                                    // key={cityItem.id}
                                    //  value={cityItem.id}
                                    >
                                        {/* {cityItem.name} */}
                                        Surat
                                    </option>
                                    {/* ))} */}
                                </select>
                                {errors.city && <p className="text-red-500 text-xs">{errors.city}</p>}
                            </div>
                        </div>
                        <div className='w-full bg-[#EBF7F9] p-4 md:p-8'>
                            <div className='font-semibold text-[#2F94A6] uppercase text-base lg:text-lg xl:text-xl 2xl:text-2xl'>KYC VERIFICATION</div>
                            <div className='text-sm lg:text-base xl:text-lg text-[#2F94A6]/50'>Please note that KYC is necessary to buy products from MedicaBuyer due to regulatory reasons.</div>
                        </div>
                        <div className='p-4 md:p-8'>
                            <div>Select any KYC proof</div>
                            <div className='bg-[#F3F4F4] rounded-lg p-4'>
                                {category.map((item, index) => (
                                    <div
                                        key={index}
                                        className='flex items-center'
                                    >
                                        <input
                                            type="radio"
                                            className="radio-large  2xl:mt-2 w-[5%]"
                                            value={item.title}
                                            checked={selectedCategory === item.title}
                                            onChange={handleChange}
                                        />
                                        <div>{item.title}</div>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className={` text-base lg:text-lg flex justify-end px-4 md:px-8 ${showCategoryForm ? 'hidden' : ''} `}>
                            <button type="submit" className={` bg-[#1A444C] inline rounded-full px-4 py-2 text-white`}>
                                Go ahead
                            </button>
                        </div>

                        {showCategoryForm && (
                            <div className='p-4 md:p-8 '>
                                {renderCategoryForm()}
                            </div>
                        )}
                    </form>
                </div>
            </div >
        </div >
    );
};

export default CompleteProfile;
