import React from 'react'
import Common from './Common'
import equipment from './../../assets/medicalEquipment.png'
import qualityProduct from './../../assets/qualityProducts.png'
import fastDelivery from './../../assets/fastDelivery.png'
import onlineShopping from './../../assets/onlineShopping.png'
import fourFunction from './fourFunctionString'

const FourFunction = () => {
    return (
        <section className="flex justify-center font-poppins" >
            <div className="">
                <div className='container px-6 py-12'>
                    <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 xl:gap-8 2xl:gap-12 justify-between w-full'>
                        <Common img={equipment} title={fourFunction.extensiveEquipmentBold} description={fourFunction.extensiveDesc} />
                        <Common img={qualityProduct} title={fourFunction.qualityProductBold} description={fourFunction.qualityDesc} />
                        <Common img={fastDelivery} title={fourFunction.fastDeliveryBold} description={fourFunction.fastDesc} />
                        <Common img={onlineShopping} title={fourFunction.onlineShoppingBold} description={fourFunction.onlineDesc} />
                    </div>
                </div>
            </div>
        </section>
    )
}

export default FourFunction
