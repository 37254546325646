import React from 'react'
import newArrivals from './newArrivalsString'
import medicalEquipment from './../../assets/medicalEquipmentNewArrival.png'
import dialysisUnit from './../../assets/dialysisUnit.png'
import consumables from './../../assets/consumablesNewArrival.png'
import firstAid from './../../assets/firstAid.png'
import CommonBigOffer from '../bigOffer/CommonBigOffer'
import { GrLinkNext, GrLinkPrevious } from 'react-icons/gr'
import Slider from 'react-slick'
import { Link } from 'react-router-dom'

// Custom Arrow Components
const PrevArrow = ({ onClick }) => {
    return (
        <button
            onClick={onClick}
            className="absolute left-0 top-[38%] sm:top-[31%] md:top-[26%] xl:top-[30%] 2xl:top-[32%] transform -translate-y-1/2 bg-white p-3 rounded-full shadow-lg z-10 hover:bg-[#1A444C] text-[#1A444C] hover:text-[#E1DFD7]"
            aria-label="Previous"
        >
            <GrLinkPrevious className='' />
        </button>
    );
};

const NextArrow = ({ onClick }) => {
    return (
        <button
            onClick={onClick}
            className="absolute right-0 top-[38%] sm:top-[31%] md:top-[26%] xl:top-[30%] 2xl:top-[32%] transform -translate-y-1/2 bg-white p-3 rounded-full shadow-lg z-10 hover:bg-[#1A444C] text-[#1A444C] hover:text-[#E1DFD7]"
            aria-label="Next"
        >
            <GrLinkNext />
        </button>
    );
};

const NewArrivals = () => {
    // Slick slider settings
    const settings = {
        dots: false, // Show dots below the slider
        infinite: true, // Enable infinite loop
        speed: 300, // Animation speed
        slidesToShow: 4, // Number of slides to show at once
        slidesToScroll: 1, // Number of slides to scroll at once
        autoplay: true, // Enable autoplay
        autoplaySpeed: 2000, // Autoplay interval in ms
        nextArrow: <NextArrow />, // Custom Next Arrow
        prevArrow: <PrevArrow />, // Custom Prev Arrow
        responsive: [
            {
                breakpoint: 1536, // Screen width <= 1536px
                settings: {
                    slidesToShow: 4,
                },
            },
            {
                breakpoint: 1280, // Screen width <= 1280px
                settings: {
                    slidesToShow: 4,
                },
            },
            {
                breakpoint: 1024, // Screen width <= 1024px
                settings: {
                    slidesToShow: 3,
                },
            },
            {
                breakpoint: 768, // Screen width <= 768px
                settings: {
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 640, // Screen width <= 640px
                settings: {
                    slidesToShow: 1,
                },
            },
        ],
    };
    return (
        <section className="flex justify-center font-poppins" >
            <div className='container px-6 py-12'>
                <div className='w-full flex justify-between items-center '>
                    <div className='w-full'>
                        <div className='flex items-center justify-between'>
                            <h2 className='font-semibold text-2xl xl:text-3xl'>{newArrivals.title}</h2>
                            <button className='underline text-[#1A444C] text-xs md:text-base lg:text-lg xl:text-xl'>
                                <Link to="/allShopCategory">
                                    {newArrivals.seeAll}
                                </Link>
                            </button>
                        </div>
                        <div className='text-[#848484] text-xs md:text-sm lg:text-base'>{newArrivals.desc}</div>
                    </div>
                    {/* <div>
                        <button className='underline text-[#1A444C] text-xs md:text-base lg:text-lg xl:text-xl'>
                            <Link to="/allShopCategory">
                                {newArrivals.seeAll}
                            </Link>
                        </button>
                    </div> */}
                </div>

                {/* Slick Slider Section */}
                <div className="py-4 ">
                    <Slider {...settings} className='relative '>
                        <CommonBigOffer data={newArrivals.medicalEquipment} img={medicalEquipment} />
                        <CommonBigOffer data={newArrivals.dialysisUnit} img={dialysisUnit} />
                        <CommonBigOffer data={newArrivals.consumables} img={consumables} />
                        <CommonBigOffer data={newArrivals.firstAidSupply} img={firstAid} />
                    </Slider>
                </div>
            </div>
        </section >
    )
}

export default NewArrivals
