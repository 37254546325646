const productPage = {
    title: "3M Littmann Classic III Monitoring Stethoscope (5803) - Blue Edition Chestpiece Blue Tube",
    price: "₹5599",
    crossPrice: "₹6599",
    off: "10% off",
    model: "Model",
    modelNo: "5684",
    keyFeature: {
        title: "Key Features",
        array: [
            "Unparalleled acoustic performance",
            "Precision-engineered for superior diagnostics",
            "Trusted by medical professionals worldwide",
            "Durable craftsmanship that stands the test of time",
            "Precision-Machined Chestpiece: The stethoscope features a precision-machined chestpiece that ensures consistent high acoustic sensitivity, allowing healthcare professionals to capture subtle heart, lung, and other body sounds with exceptional clarity.",
            "Durable Construction: Made from robust yet lightweight materials, the stethoscope offers durability without compromising comfort, making it a reliable clinical tool for daily use.",
            "Next-Generation Tubing: Resilient single-lumen tubing contributes to the stethoscope's longer product life, providing healthcare professionals with a dependable instrument for continuous clinical assessments.",
            "Tunable Technology: Nearly all Classic stethoscopes, including this Black Edition, feature tunable technology, offering versatility in adjusting frequency response to suit different diagnostic needs.",
            "Compact Design: The stethoscope's compact design makes it an ideal tool for physical assessment and monitoring of patients, providing convenience without sacrificing performance.",
        ],
    },
    productDescription: 'Product Description',
    productDescriptionText: 'Elevate your clinical practice with the 3M Littmann Classic III Monitoring Stethoscope (5803) - Blue Edition, a sophisticated instrument designed for effective monitoring and physical assessment of patients, both pediatric and adult. Crafted with a sleek blue chestpiece and tube, this stethoscope seamlessly combines style and functionality.',
    warranty: 'Warranty',
    warrantyText: 'Stethoscope: 5 Years',
    cart: {
        quantity: "Quantity",
        subTotal: "Subtotal",
        addToCart: 'Add to Cart',
        buyNow: "Buy Now",
    },
    alsoBuyTheseProducts: {
        title: "Buyers of this product also buy these products",
        mostTitle: "Most selling products",
        littmann: {
            title: "3M Littmann Classic III Monitoring Inject...",
            price: "₹5599",
            crossPrice: '₹6599',
            addToCartBtn: "Add to Cart",
        },
    },
}
export default productPage