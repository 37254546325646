const policyString = {
    returnAndRefundString: {
        title: "Returns & Refund Policy",
        policy: {
            main: {
                para: "The MedicaBuyer event organized by IMR Media (Organizer) will take place at the Manekshaw Centre, New Delhi on  28-29 Nov 2024. In case of circumstances beyond the control of the Organizers (referred to as force majeure), the Organizer may choose to change the  dates or the venue of this event or cancel it after notifying the participants.",
            },
            registration: {
                bold: "Registration : ",
                para: "Your MedicaBuyer 2024 registration will only be effective upon payment, acceptance by the organizer  and sending guidelines for participation..",
            },
            cancellationsAndRefunds: {
                bold: "Cancellations and Refunds : ",
                para: "The contract is concluded and binding. Refunds are subject to the Refund Policy as follows:",
                li: [
                    "If any of the Business Meetings Packages (A1, A2, A3, A4) purchases are cancelled before 15 August 2024, on your request we will refund the whole amount after deducting Rs 2000 and 10% of purchase amount. After 15 August 2024, no refunds are possible for Business Meetings Packages.",
                    " In case of all other options - Sponsorship, Exhibition, Branding, and Communications - refunds will be made up to 15 Sep 2024 after deducting 25% of the total cost of the bill. Thereafter, no refunds are possible.",
                ],
            },
            obligation: {
                bold: "Best Efforts Obligation : ",
                para: "IMR Media Pvt Ltd as the Organizer are bound only by a best efforts obligation.",
            },
            meeting: {
                bold: "Meeting Module :",
                para: "All participants must pay the full price before the opening of the  Meeting Module, in order to have their corporate  profiles published in this catalogue/ website.",
            },
            preScheduleMeeting: {
                bold: "Pre-scheduled Meetings :",
                para: "Participants are expected to honour their scheduled meetings.",
            },
            ipr: {
                bold: "IPR :",
                para: "The Organizer will have the right to use all event materials, including but not limited to presentations and documents.",
            },
            conduct: {
                bold: "Conduct :",
                para: " Participants are expected to conduct themselves professionally and ethically during all event activities. The Organizer reserves the  right to terminate a participant's participation in the event for violation of these terms and conditions or disruptive behaviour.",
            },
            maintenance: {
                bold: "Maintenance of the booth :",
                para: " Each participant commits to assure the maintenance of his booth and to be present at it during the event. No materials  shall be exhibited by the participants outside or above their allocated booth.",
            },
        },
    },
    privacyPolicy: {
        title: "Privacy Policy",
        policy: {
            main: {
                para: "The MedicaBuyer event organized by IMR Media (Organizer) will take place at the Manekshaw Centre, New Delhi on  28-29 Nov 2024. In case of circumstances beyond the control of the Organizers (referred to as force majeure), the Organizer may choose to change the  dates or the venue of this event or cancel it after notifying the participants.",
            },
            registration: {
                bold: "Registration :",
                para: "Your MedicaBuyer 2024 registration will only be effective upon payment, acceptance by the organizer  and sending guidelines for participation..",
            },
            cancellationsAndRefunds: {
                bold: "Cancellations and Refunds : ",
                para: "The contract is concluded and binding. Refunds are subject to the Refund Policy as follows:",
                li: [
                    "If any of the Business Meetings Packages (A1, A2, A3, A4) purchases are cancelled before 15 August 2024, on your request we will refund the whole amount after deducting Rs 2000 and 10% of purchase amount. After 15 August 2024, no refunds are possible for Business Meetings Packages.",
                    " In case of all other options - Sponsorship, Exhibition, Branding, and Communications - refunds will be made up to 15 Sep 2024 after deducting 25% of the total cost of the bill. Thereafter, no refunds are possible.",
                ],
            },
            obligation: {
                bold: "Best Efforts Obligation : ",
                para: "IMR Media Pvt Ltd as the Organizer are bound only by a best efforts obligation.",
            },
            meeting: {
                bold: "Meeting Module :",
                para: "All participants must pay the full price before the opening of the  Meeting Module, in order to have their corporate  profiles published in this catalogue/ website.",
            },
            preScheduleMeeting: {
                bold: "Pre-scheduled Meetings :",
                para: "Participants are expected to honour their scheduled meetings.",
            },
            ipr: {
                bold: "IPR :",
                para: "The Organizer will have the right to use all event materials, including but not limited to presentations and documents.",
            },
            conduct: {
                bold: "Conduct :",
                para: " Participants are expected to conduct themselves professionally and ethically during all event activities. The Organizer reserves the  right to terminate a participant's participation in the event for violation of these terms and conditions or disruptive behaviour.",
            },
            maintenance: {
                bold: "Maintenance of the booth :",
                para: " Each participant commits to assure the maintenance of his booth and to be present at it during the event. No materials  shall be exhibited by the participants outside or above their allocated booth.",
            },
            information: {
                bold: "Information Management :",
                para: " The event platform may involve third-party services; participants are subject to the terms and conditions of those  services. By registering to the event, each participant authorizes IMR Media Pvt Ltd to use his email address(es) and mobile numbers,  for dispatching information regarding the event and promotion of other events organized by IMR Media Pvt Ltd. These details may also be transmitted  to the organizing partners, This information will allow informing the participants about upcoming events and receiving information by email; with  personalized communication including sending newsletters, special offers and special emails within the communication framework of the event.  Moreover, IMR Media Pvt Ltd reiterates that if the participant changes his mind and no longer wishes to receive certain categories of emails, he can at  any moment contact us in order to keep us informed about these mailings.",
            },
            insurance: {
                bold: "Insurance :",
                para: "It is the responsibility of each participant to have insurance covering its legal liability and all damages for which the participant may be  held responsible, such as: theft, fire, deterioration, damage or destruction of any equipment or facility.  IMR Media Pvt Ltd and its insurers waive all claims  against the exhibitors and their insurers, reciprocally, all participants and their insurers waive all claims against IMR Media Pvt Ltd and its insurers.",
            },
            advertising: {
                bold: "Advertising :",
                para: "Subsequent to the acceptance of the registration, the organizer authorizes all participants to mention the following sentence on its  sales and advertising correspondence: “Participating in MedicaBuyer, New Delhi on 28-29 Nov 2024.” Likewise, each  participant authorizes IMR Media Pvt Ltd to communicate about his/her attendance to this business convention.",
            },
        }
    },
    termsOfUse: {
        title: "Terms of Use",
        policy: {
            main: {
                para: "The MedicaBuyer event organized by IMR Media (Organizer) will take place at the Manekshaw Centre, New Delhi on  28-29 Nov 2024. In case of circumstances beyond the control of the Organizers (referred to as force majeure), the Organizer may choose to change the  dates or the venue of this event or cancel it after notifying the participants.",
            },
            registration: {
                bold: "Registration :",
                para: "Your MedicaBuyer 2024 registration will only be effective upon payment, acceptance by the organizer  and sending guidelines for participation..",
            },
            cancellationsAndRefunds: {
                bold: "Cancellations and Refunds : ",
                para: "The contract is concluded and binding. Refunds are subject to the Refund Policy as follows:",
                li: [
                    "If any of the Business Meetings Packages (A1, A2, A3, A4) purchases are cancelled before 15 August 2024, on your request we will refund the whole amount after deducting Rs 2000 and 10% of purchase amount. After 15 August 2024, no refunds are possible for Business Meetings Packages.",
                    " In case of all other options - Sponsorship, Exhibition, Branding, and Communications - refunds will be made up to 15 Sep 2024 after deducting 25% of the total cost of the bill. Thereafter, no refunds are possible.",
                ],
            },
            obligation: {
                bold: "Best Efforts Obligation : ",
                para: "IMR Media Pvt Ltd as the Organizer are bound only by a best efforts obligation.",
            },
            meeting: {
                bold: "Meeting Module :",
                para: "All participants must pay the full price before the opening of the  Meeting Module, in order to have their corporate  profiles published in this catalogue/ website.",
            },
            preScheduleMeeting: {
                bold: "Pre-scheduled Meetings :",
                para: "Participants are expected to honour their scheduled meetings.",
            },
            ipr: {
                bold: "IPR :",
                para: "The Organizer will have the right to use all event materials, including but not limited to presentations and documents.",
            },
            conduct: {
                bold: "Conduct :",
                para: " Participants are expected to conduct themselves professionally and ethically during all event activities. The Organizer reserves the  right to terminate a participant's participation in the event for violation of these terms and conditions or disruptive behaviour.",
            },
            maintenance: {
                bold: "Maintenance of the booth :",
                para: " Each participant commits to assure the maintenance of his booth and to be present at it during the event. No materials  shall be exhibited by the participants outside or above their allocated booth.",
            },
            information: {
                bold: "Information Management :",
                para: " The event platform may involve third-party services; participants are subject to the terms and conditions of those  services. By registering to the event, each participant authorizes IMR Media Pvt Ltd to use his email address(es) and mobile numbers,  for dispatching information regarding the event and promotion of other events organized by IMR Media Pvt Ltd.",
            },
        }
    },
    shoppingPolicy: {
        title: "Shipping Policy",
        policy: {
            main: {
                para: "The MedicaBuyer event organized by IMR Media (Organizer) will take place at the Manekshaw Centre, New Delhi on  28-29 Nov 2024. In case of circumstances beyond the control of the Organizers (referred to as force majeure), the Organizer may choose to change the  dates or the venue of this event or cancel it after notifying the participants.",
            },
            registration: {
                bold: "Registration :",
                para: "Your MedicaBuyer 2024 registration will only be effective upon payment, acceptance by the organizer  and sending guidelines for participation..",
            },
            cancellationsAndRefunds: {
                bold: "Cancellations and Refunds : ",
                para: "The contract is concluded and binding. Refunds are subject to the Refund Policy as follows:",
                li: [
                    "If any of the Business Meetings Packages (A1, A2, A3, A4) purchases are cancelled before 15 August 2024, on your request we will refund the whole amount after deducting Rs 2000 and 10% of purchase amount. After 15 August 2024, no refunds are possible for Business Meetings Packages.",
                    " In case of all other options - Sponsorship, Exhibition, Branding, and Communications - refunds will be made up to 15 Sep 2024 after deducting 25% of the total cost of the bill. Thereafter, no refunds are possible.",
                ],
            },
            obligation: {
                bold: "Best Efforts Obligation : ",
                para: "IMR Media Pvt Ltd as the Organizer are bound only by a best efforts obligation.",
            },
            meeting: {
                bold: "Meeting Module :",
                para: "All participants must pay the full price before the opening of the  Meeting Module, in order to have their corporate  profiles published in this catalogue/ website.",
            },
            preScheduleMeeting: {
                bold: "Pre-scheduled Meetings :",
                para: "Participants are expected to honour their scheduled meetings.",
            },
            ipr: {
                bold: "IPR :",
                para: "The Organizer will have the right to use all event materials, including but not limited to presentations and documents.",
            },
            conduct: {
                bold: "Conduct :",
                para: " Participants are expected to conduct themselves professionally and ethically during all event activities. The Organizer reserves the  right to terminate a participant's participation in the event for violation of these terms and conditions or disruptive behaviour.",
            },
            maintenance: {
                bold: "Maintenance of the booth :",
                para: " Each participant commits to assure the maintenance of his booth and to be present at it during the event. No materials  shall be exhibited by the participants outside or above their allocated booth.",
            },
            information: {
                bold: "Information Management :",
                para: " The event platform may involve third-party services; participants are subject to the terms and conditions of those  services. By registering to the event, each participant authorizes IMR Media Pvt Ltd to use his email address(es) and mobile numbers,  for dispatching information regarding the event and promotion of other events organized by IMR Media Pvt Ltd. These details may also be transmitted  to the organizing partners, This information will allow informing the participants about upcoming events and receiving information by email; with  personalized communication including sending newsletters, special offers and special emails within the communication framework of the event.  Moreover, IMR Media Pvt Ltd reiterates that if the participant changes his mind and no longer wishes to receive certain categories of emails, he can at  any moment contact us in order to keep us informed about these mailings.",
            },
            insurance: {
                bold: "Insurance :",
                para: "It is the responsibility of each participant to have insurance covering its legal liability and all damages for which the participant may be  held responsible, such as: theft, fire, deterioration, damage or destruction of any equipment or facility.  IMR Media Pvt Ltd and its insurers waive all claims  against the exhibitors and their insurers, reciprocally, all participants and their insurers waive all claims against IMR Media Pvt Ltd and its insurers.",
            },
            advertising: {
                bold: "Advertising :",
                para: "Subsequent to the acceptance of the registration, the organizer authorizes all participants to mention the following sentence on its  sales and advertising correspondence: “Participating in MedicaBuyer, New Delhi on 28-29 Nov 2024.” Likewise, each  participant authorizes IMR Media Pvt Ltd to communicate about his/her attendance to this business convention.",
            },
        }
    },
}
export default policyString;