import React from 'react';
import backArrow from './../../assets/backButton.png';
import Category from '../../components/Category';
import policyString from './policyString';
import { useNavigate } from 'react-router-dom';

const ReturnsAndReturn = ({ policyTitle, policyArray }) => {
    const renderPolicySection = (section) => (
        <div className="my-4">
            <div>
                <p className="mt-2 text-justify text-base lg:text-lg xl:text-xl">
                    <span className="font-semibold ">{section.bold} </span>
                    {section.para || <>&nbsp;</>}
                </p>
            </div>
            {section.li ? (
                <ul className="list-disc ml-6 text-justify">
                    {section.li.map((item, index) => (
                        <li key={index}>{item || <>&nbsp;</>}</li>
                    ))}
                </ul>
            ) : (
                <></>
            )}
        </div>
    );

    const navigate = useNavigate();
    const handleClose = (e) => {
        navigate(-1);
    }

    return (
        <>
            <Category />
            <section className="flex justify-center font-poppins min-h-screen h-full">
                <div className="container px-6 py-12">
                    <div className="w-full flex justify-between items-center">
                        <div className="flex items-center gap-2">
                            <img onClick={handleClose} src={backArrow} alt="Back" />
                            <h2 className="font-semibold text-xl lg:text-2xl xl:text-3xl">{policyTitle}</h2>
                        </div>
                    </div>

                    <div className="mt-8">
                        {Object.values(policyArray).map((section, index) => (
                            <div key={index}>
                                {renderPolicySection(section)}
                            </div>
                        ))}
                    </div>
                </div>
            </section>
        </>
    );
};

export default ReturnsAndReturn;
