const fourFunction = {
    extensiveEquipmentBold: "Extensive Range of Medical Supplies and Equipment",
    extensiveDesc: "Discover a wide variety of prescription medications, over-the-counter drugs, and essential medical supplies all in one place.",
    qualityProductBold: "Quality Products at affordable price",
    qualityDesc: "Our licensed pharmacists provide professional advice and personalized guidance for safe and effective medication use.",
    fastDeliveryBold: 'Fast & Reliable Delivery',
    fastDesc: "Enjoy quick, secure delivery services, ensuring your orders reach you promptly and in perfect condition.",
    onlineShoppingBold: "Intuitive Online Shopping",
    onlineDesc: "Navigate our intuitive website with ease, find what you need quickly, and complete your purchases hassle-free.",
}
export default fourFunction