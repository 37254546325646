import React, { useEffect, useRef } from "react";
import activationSuccess from "./../../../assets/AccountSuccess.png";
import signIn from "../signInString";
import cross from "./../../../assets/cross.png";

const ActivationSuccess = ({ onClose, title, text }) => {
  const signInRef = useRef();

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (signInRef.current && !signInRef.current.contains(e.target)) {
        onClose(); // Corrected this line
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClose]);

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-sm flex flex-col justify-center items-center z-50 ">
      <div className="flex justify-end items-end max-w-7xl w-[90%] py-1">
        <button className="bg-white rounded-full p-2" onClick={onClose}>
          <img src={cross} alt="Close" className="lg:size-3" />
        </button>
      </div>
      <div
        ref={signInRef}
        className="bg-white rounded-3xl shadow-lg max-w-7xl w-[90%] h-auto max-h-[90%] overflow-y-auto relative p-8 "
      >
        <div className="flex flex-col gap-4">
          <div className="flex items-center justify-center">
            <img src={activationSuccess} alt="Account Success" />
          </div>
          <h1 className='font-bold text-lg md:text-xl lg:text-2xl xl:text-3xl 2xl:text-4xl text-center'>{title}</h1>
          <div className="w-[90%] md:w-[80%] lg:w-[70%] mx-auto text-[#0F0F0F]/50 text-sm md:text-base lg:text-lg text-center">{text}</div>
          <div className="text-base lg:text-lg flex justify-center pt-8 px-4 md:px-8">
            <button
              type="button"
              className="bg-[#1A444C] inline rounded-full px-4 py-2 text-white"
              onClick={onClose}
            >
              {signIn.accountSuccess.btn}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ActivationSuccess;
