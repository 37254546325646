// src/redux/wishlistSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    items: [], // Stores the liked items (wishlist)
    loading: false, // To handle async actions
    error: null // For error handling when using an API
};

const wishlistSlice = createSlice({
    name: 'wishlist',
    initialState,
    reducers: {
        addToWishlist: (state, action) => {
            const existingItem = state.items.find(item => item.id === action.payload.id);
            if (!existingItem) {
                state.items.push(action.payload);
            }
        },
        removeFromWishlist: (state, action) => {
            state.items = state.items.filter(item => item.id !== action.payload);
        },
        setWishlist: (state, action) => {
            // This will allow setting the wishlist from an API
            state.items = action.payload;
        },
        clearWishlist: (state) => {
            state.items = [];
        },
        setLoading: (state, action) => {
            state.loading = action.payload;
        },
        setError: (state, action) => {
            state.error = action.payload;
        }
    },
});

export const {
    addToWishlist,
    removeFromWishlist,
    setWishlist,
    clearWishlist,
    setLoading,
    setError
} = wishlistSlice.actions;

export default wishlistSlice.reducer;
