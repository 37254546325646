import React from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import mainProductImage from './../../assets/mainProductImage.png'
import subCategoryImage1 from './../../assets/subProductImage1.png'
import subCategoryImage2 from './../../assets/subProductImage2.png'
import subCategoryImage3 from './../../assets/subProductImage3.png'
import subCategoryImage4 from './../../assets/subProductImage4.png'
import productPage from './productPageString'
import minus from './../../assets/minus.png'
import plus from './../../assets/plus.png'
import { useDispatch, useSelector } from 'react-redux'
import { addCartItem, decrementQuantity, incrementQuantity } from '../../reduxStore/cartSlice'
import alsoBuy1 from './../../assets/alsoBuy1.png'
import alsoBuy2 from './../../assets/alsoBuy2.png'
import alsoBuy3 from './../../assets/alsoBuy3.png'
import alsoBuy4 from './../../assets/alsoBuy4.png'
import alsoBuy5 from './../../assets/alsoBuy5.png'
import alsoBuy6 from './../../assets/alsoBuy6.png'
import CommonBigOffer from '../../components/bigOffer/CommonBigOffer'
import { addToCart } from '../../apiEndPoint'

const ProductPage = () => {
  const location = useLocation();
  const { data } = location.state || {};
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { items, quantity, status, error } = useSelector((state) => state.cart);
  const handleIncrement = () => {
    dispatch(incrementQuantity());
  };
  const discPrice = data.variation[0].price - (data.variation[0].price * (data.variation[0].discount / 100));

  const handleDecrement = () => {
    dispatch(decrementQuantity());
  };

  // Function to extract number from price string and calculate total price
  const calculateTotalPrice = () => {
    // const priceValue = parseInt(discPrice.replace(/[^\d]/g, ''), 10); // Extract number from string
    return discPrice * quantity; // Multiply with quantity
  };

  const handleAddToCart = (productId, quantity) => {
    dispatch(addCartItem({ productId, quantity }));
    console.log("Success", productId, quantity);
  };

  return (
    <section className="flex justify-center font-poppins" >
      <div className='container px-6 py-12'>
        <div className='flex items-center gap-2 py-4'>
          <button className='text-[#848484] '>
            <Link to="/" className='flex items-center '>
              Home
            </Link>
          </button>
          <button className='text-[#848484] flex items-center '>
            / Best Sellers
          </button>
        </div>
        <div className='grid grid-cols-1 lg:grid-cols-3 gap-4 lg:gap-6'>
          <div className='grid grid-cols-4 gap-2 h-min'>
            <div className='col-span-1 flex flex-col gap-1 justify-between'>
              <img src={`${process.env.REACT_APP_BASE_URL}/${data.image}`} alt="" crossOrigin="anonymous" />
              <img src={`${process.env.REACT_APP_BASE_URL}/${data.image}`} alt="" crossOrigin="anonymous" />
              <img src={`${process.env.REACT_APP_BASE_URL}/${data.image}`} alt="" crossOrigin="anonymous" />
              <img src={`${process.env.REACT_APP_BASE_URL}/${data.image}`} alt="" crossOrigin="anonymous" />
            </div>
            <div className='col-span-3'>
              <img src={`${process.env.REACT_APP_BASE_URL}/${data.image}`} alt="" className='w-full h-full' crossOrigin="anonymous" />
            </div>
          </div>
          <div>
            <h2 className='text-2xl xl:text-3xl' onClick={() => console.log(data._id)}>{data.name}</h2>
            <div className='flex items-center pt-2 gap-1 '>
              <p className='text-[#EF2D2D] font-semibold text-lg md:text-xl lg:text-3xl'>{discPrice}</p>
              <p className='text-[#848484] text-base md:text-lg lg:text-2xl line-through'>{data.variation[0].price}</p>
              <p className='text-[#43B678] text-base md:text-lg lg:text-2xl'>{`${data.variation[0].discount}% off`}</p>
            </div>
            <div className='py-12'>
              <div className='text-[#848484] text-sm md:text-base lg:text-lg'>{productPage.model}</div>
              <div className='p-4 bg-[#F5F7F7] inline-block font-semibold'>{productPage.modelNo}</div>
            </div>
            <div className='p-4 rounded-xl border-[0.5px] border-[#848484]/25'>
              {/* <div className='py-2'>
                                <div className='text-[#2F94A6] font-semibold text-base lg:text-lg'>{productPage.keyFeature.title}</div>
                                <div>
                                    <ul className='list-disc pl-5'>
                                        {productPage.keyFeature.array.map((item, index) => (
                                            <li key={index} className='text-sm lg:text-base '>{item}</li>
                                        ))}
                                    </ul>
                                </div>
                            </div> */}
              <div className="py-2">
                <div className="text-[#2F94A6] font-semibold text-base lg:text-lg">
                  {productPage.productDescription}
                </div>
                <div className="text-sm lg:text-base">{data.description}</div>
              </div>
              <div className="py-2">
                <div className="text-[#2F94A6] font-semibold text-base lg:text-lg">
                  {productPage.warranty}
                </div>
                <div className="text-sm lg:text-base ">
                  {productPage.warrantyText}
                </div>
              </div>
            </div>
          </div>
          <div className="p-4 rounded-xl border border-[#848484]/25 h-min">
            <div className="text-[#848484] flex items-center justify-between border-b py-4 pb-5 border-[#848484]/25">
              <div>{productPage.cart.quantity}</div>
              <div className="flex items-center gap-2">
                <button onClick={handleDecrement} className="">
                  <img src={minus} alt="Decrease Quantity" />
                </button>
                <div>
                  {quantity} {/* Display the current quantity */}
                </div>
                <button onClick={handleIncrement} className="">
                  <img src={plus} alt="Increase Quantity" />
                </button>
              </div>
            </div>
            <div className="text-[#848484] font-semibold pt-4 pb-12 flex items-center justify-between">
              <div>{productPage.cart.subTotal}</div>
              <div className="text-[#EF2D2D] font-semibold text-base md:text-lg lg:text-2xl">
                ₹{calculateTotalPrice()}
              </div>
            </div>
            <button
              onClick={() => handleAddToCart(data._id, quantity)}
              className="border border-[#1A444C]/50 text-[#1A444C] hover:bg-[#1A444C] hover:text-white hover:font-semibold block w-full p-2 rounded-lg my-2"
            >
              {productPage.cart.addToCart}
            </button>
            <button className="border border-[#1A444C]/50 text-[#1A444C] hover:bg-[#1A444C] hover:text-white hover:font-semibold block w-full p-2 rounded-lg my-2">
              {productPage.cart.buyNow}
            </button>
          </div>
        </div>
        <div className="w-full py-8">
          <div>
            <h2 className="font-semibold text-2xl xl:text-3xl">
              {productPage.alsoBuyTheseProducts.title}
            </h2>
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6 -mx-4">
            <CommonBigOffer
              data={productPage.alsoBuyTheseProducts.littmann}
              img={alsoBuy1}
              font={"font-normal"}
            />
            <CommonBigOffer
              data={productPage.alsoBuyTheseProducts.littmann}
              img={alsoBuy2}
              font={"font-normal"}
            />
            <CommonBigOffer
              data={productPage.alsoBuyTheseProducts.littmann}
              img={alsoBuy3}
              font={"font-normal"}
            />
            <CommonBigOffer
              data={productPage.alsoBuyTheseProducts.littmann}
              img={alsoBuy4}
              font={"font-normal"}
            />
            <CommonBigOffer
              data={productPage.alsoBuyTheseProducts.littmann}
              img={alsoBuy5}
              font={"font-normal"}
            />
            <CommonBigOffer
              data={productPage.alsoBuyTheseProducts.littmann}
              img={alsoBuy6}
              font={"font-normal"}
            />
          </div>
        </div>
        <div className="w-full py-8">
          <div>
            <h2 className="font-semibold text-2xl xl:text-3xl">
              {productPage.alsoBuyTheseProducts.mostTitle}
            </h2>
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6 -mx-4">
            <CommonBigOffer
              data={productPage.alsoBuyTheseProducts.littmann}
              img={alsoBuy1}
              font={"font-normal"}
            />
            <CommonBigOffer
              data={productPage.alsoBuyTheseProducts.littmann}
              img={alsoBuy2}
              font={"font-normal"}
            />
            <CommonBigOffer
              data={productPage.alsoBuyTheseProducts.littmann}
              img={alsoBuy3}
              font={"font-normal"}
            />
            <CommonBigOffer
              data={productPage.alsoBuyTheseProducts.littmann}
              img={alsoBuy4}
              font={"font-normal"}
            />
            <CommonBigOffer
              data={productPage.alsoBuyTheseProducts.littmann}
              img={alsoBuy5}
              font={"font-normal"}
            />
            <CommonBigOffer
              data={productPage.alsoBuyTheseProducts.littmann}
              img={alsoBuy6}
              font={"font-normal"}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default ProductPage;
