import React, { useEffect, useRef, useState } from 'react'
import backArrow from './../../assets/backButton.png'
import Notification from '../contactUs/Notification';

const CreateNewInquiry = ({ title, disc, onClose }) => {
    const [messageReceived, setMessageReceived] = useState(false);
    const [description, setDescription] = useState(''); // State for textarea input

    // Function to open the inquiry form
    const openNotification = () => {
        setMessageReceived(true);
        console.log("User's query description:", description); // Log the textarea value to console
        setDescription(''); // Reset the textarea after printing the value
    };

    // Function to handle textarea input change
    const handleDescriptionChange = (e) => {
        setDescription(e.target.value);
    };

    const signInRef = useRef();
    useEffect(() => {
        const handleClickOutside = (e) => {
            if (signInRef.current && !signInRef.current.contains(e.target)) {
                onClose();
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [onClose]);

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-sm flex flex-col justify-center items-center z-50">
            <div ref={signInRef} className='flex flex-col justify-start items-start w-[90%] lg:w-[60%] bg-white rounded-xl py-1 '>
                <button className='bg-white rounded-full py-4 px-4 sm:px-8' onClick={onClose}>
                    <img src={backArrow} alt="" className='lg:size-3' />
                </button>
                <div className='w-full px-4 sm:px-8'>
                    <h3 className='text-lg md:text-xl lg:text-2xl xl:text-3xl font-semibold'>{title}</h3>
                    <div className=' text-xs sm:text-sm lg:text-base text-[#848484] lg:py-2'>{disc}</div>
                    <div className='py-2 text-sm lg:text-base xl:text-lg'>
                        <div className=' text-[#848484]'>Describe your query*</div>
                        <textarea
                            name="description"
                            id="description"
                            placeholder='Write description here'
                            className='w-full bg-[#F3F4F4] rounded-xl p-4'
                            value={description}
                            onChange={handleDescriptionChange}
                        />
                    </div>
                    <div className='text-right py-2'>
                        <button className='rounded-lg bg-[#1A444C] text-white px-2 py-3' onClick={openNotification}>Create</button>
                    </div>
                </div>
                {messageReceived && (
                    <Notification onClose={onClose} text={"We’ve received your message. Thank you for reaching out to us. We will resolve your issue and get back to you shortly. Thank you so much for writing your issue. it helps us improve."} ok={"Okay"} />
                )}
            </div>
        </div>
    )
}

export default CreateNewInquiry
